import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { useDebounce } from 'use-debounce';
import { makeStyles, Snackbar } from '@material-ui/core';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router';

import {
  getProductTv as getProductTvProps,
  postProduct as postProductProps,
  deleteProduct as deleteProductProps,
  putProduct as putProductProps,
  uploadImage as uploadImageProps,
  resetState as resetStateProps,
} from '@myrepublic-products/productAction';
import { Button, Modal } from 'react-bootstrap';
import { columns } from './column';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  container: {
    maxHeight: 640,
  },
}));

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ProductsTv(props) {
  const {
    getProductTv,
    getProductsTvResponse,
    deleteProductResponse,
    deleteProduct,
    pagination,
    resetState,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [onGetData, setOnGetData] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);
  const [selectedFile, setSelectedFile] = useState(null);
  const history = useHistory();

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const [deleteParam, setDeleteParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: true,
  });
  const [editParam, setEditParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: true,
  });
  const [data, setData] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: true,
  });
  const getDataTable = () => {
    const initialLimit = {
      limit: 10,
      offset: 0,
    };
    getProductTv({ ...pagination });
  };

  const toogleModal = (row, data) => {
    if (data?.name === 'delete') {
      handleDelete(row);
    } else if (data?.name === 'update') {
      handleEdit(row);
    }
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    deleteProduct({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };

  const modalShowData = () => {
    // setShowCreateModal(true);
    resetState({});
    history.push('/create-producttv');
  };
  const handleDelete = (row) => {
    setDeleteParam({
      icon: row?.icon,
      code: row?.code,
      name: row?.name,
      description: row?.description,
      data: row,
      addOnItemListName: row?.addOnItems[0]?.name,
      addOnItemListPrice: row?.addOnItems[0]?.price,
      tv: true,
    });
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    resetState({});
    setEditParam({
      icon: row?.icon,
      name: row?.name,
      code: row?.code,
      description: row?.description,
      data: row,
      addOnItemListName: row?.addOnItems[0]?.name,
      addOnItemListPrice: row?.addOnItems[0]?.price,
      tv: true,
    });
    history.push('/update-producttv/' + row?.id);
  };
  const onUploadImage = (image) => {
    if (image?.length > 0) {
      setSelectedFile(image[0]);
      setData({
        ...data,
        icon: image[0],
      });
    }
  };
  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={modalShowData}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };
  useEffect(() => {
    getDataTable();
  }, [deleteProductResponse, pagination]);

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={getProductsTvResponse?.totalElements}
        totalPage={pagination.limit}
        wrapTitle={'Tv'}
        data={getProductsTvResponse?.content}
        columns={columns(toogleModal)}
        withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  getProductsTvResponse: state.product.getProductsTvResponse,
  deleteProductResponse: state.product.deleteProductResponse,
  postProductResponse: state.product.postProductResponse,
  putProductResponse: state.product.putProductResponse,
  uploadImageResponse: state.product.uploadImageResponse,
  uploadImageError: state.product.uploadImageError,
  icon: state.product.icon,
  pagination: state.paging.pagination,
});

const mapDispatchToProps = {
  getProductTv: (payload) => getProductTvProps(payload),
  postProduct: (payload) => postProductProps(payload),
  deleteProduct: (payload) => deleteProductProps(payload),
  putProduct: (payload) => putProductProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  resetState: () => resetStateProps(),
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsTv);
