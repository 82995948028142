import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'reactstrap';
import { 
  postCSAgentChatMonitoring as postCSAgentChatMonitoringProps,
  postCSAgentForceLogout as postCSAgentForceLogoutProps
} from '@myrepublic-livechat/liveChatAction';
import { columns } from './column';
import { useHistory } from 'react-router';

function OpenConfirm(props) {
  const { 
    handleClose, 
    handleClickOpen,
    open 
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Monitoring(props) {
  const {
    postCSAgentChatMonitoring,
    postCSAgentChatMonitoringResponse,
    postCSAgentForceLogout,
    postCSAgentForceLogoutResponse
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [totalData, setTotalData] = useState();
  const [roleId, setRoleId] = useState();
  const history = useHistory();


  const getDataTable = () => {
    postCSAgentChatMonitoring({limit: 10, offset: 0});
  };

  useEffect(() => {
    getDataTable();
  }, [postCSAgentForceLogoutResponse]);

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    setOpenModalDelete(false);
  };


  const handleForceLogout = (row) => {
    console.log("click", row)
    postCSAgentForceLogout({ "csagentId": row?.csagentId})
  }

  useEffect(() => {
    console.log('postCSAgentChatMonitoringResponse', postCSAgentChatMonitoringResponse);
  }, [postCSAgentChatMonitoringResponse])


  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={totalData}
        totalPage={totalData}
        wrapTitle={'Monitoring'}
        data={postCSAgentChatMonitoringResponse?.content}
        columns={columns(handleForceLogout)}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  postCSAgentChatMonitoringResponse: state.livechat.postCSAgentChatMonitoringResponse,
  postCSAgentForceLogoutResponse: state.livechat.postCSAgentForceLogoutResponse
});

const mapDispatchToProps = {
  postCSAgentChatMonitoring: (payload) => postCSAgentChatMonitoringProps(payload),
  postCSAgentForceLogout: (payload) => postCSAgentForceLogoutProps(payload)
};
export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);

