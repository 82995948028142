import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';

export const columns = (onClickUpdateIcon, toogleDeleteConfirmModal, handleActive) => [
  {
    dataField: '#',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'woNumber',
    text: 'Work Order Number',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: '#',
    text: 'Customer Id',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return <div>{row?.customer?.id}</div>
    },
  },
  {
    dataField: 'ticketCategory',
    text: 'Ticket Category',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'ticketNumber',
    text: 'Ticket Number',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'woStatus',
    text: 'Work Order Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'woServiceStatus',
    text: 'WO Service Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'woServiceType',
    text: 'WO Service Type',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'woTechnicianStatus',
    text: 'WO Technician Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'description',
    text: 'Description',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
];
