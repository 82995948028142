import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { columns } from './column';
import { useHistory } from 'react-router';
import { 
  // getVendor as getVendorProps,
  // putVendorStatus as putVendorStatusProps,
  // resetState as resetStateProps 
  postVendorStatistics as postVendorStatisticsProps
} from '@myrepublic-wokumo/wokumoAction'

function VendorMonitoring(props) {
  const {
    postVendorStatistics,
    postVendorStatisticsResponse,
    pagination
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);

  const history = useHistory();

  const getDataTable = () => {
    postVendorStatistics({
          ...pagination,
          filter: {}
    });
  };

  useEffect(() => {
    getDataTable();
  }, [pagination])

  useEffect(() => {
    console.log("postVendorStatisticsResponse", postVendorStatisticsResponse)
  }, [postVendorStatisticsResponse])

  const handleActive = (row) => {
    // putVendorStatus({id: row.id, body: {statusActive: !row.statusActive}});
  }

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };

  const handleAdd = () => {
    // resetState({});
    history.push('/create-vendor')
  };
  const handleEdit = (row) => {
    console.log('row is', row)
    history.push(`/update-vendor/${row.id}`);
  };

  const modalShowData = () => {
    history.push('/create-vendor');
  };

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={postVendorStatisticsResponse?.totalElements || 0}
        totalPage={pagination.limit}
        wrapTitle={'Vendor Monitoring'}
        data={postVendorStatisticsResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
      >
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postVendorStatisticsResponse: state.wokumo.postVendorStatisticsResponse,
});

const mapDispatchToProps = {
  // resetState: () => resetStateProps(),
  postVendorStatistics: (payload) => postVendorStatisticsProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorMonitoring);

