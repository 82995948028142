import { BASE_AUTH, BASE_URL } from '@myrepublic-config/env';
import axios from 'axios';

export const apiAuth = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Headers': '*',
  },
  baseURL: BASE_AUTH,
});

export const getTableApi = (payload) => {
  console.log('load=>>>', payload);
  const { accessToken, path, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`${path}`, data);
};

export const api = axios.create({
  // timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
  baseURL: BASE_URL,
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  api.interceptors.request.use((request) => {
    console.log('>>> Request', request);
    return request;
  });

  api.interceptors.response.use(
    (response) => {
      console.log('<<< Response:', response);
      return response;
    },
    (error) => {
      console.log('*** ', error);
      return Promise.reject(error);
    }
  );
}
