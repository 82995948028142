export const paginationLimit = {
  pagination: {
    limit: 10,
    offset: 0,
    load: false,
  },
};

export const dataTableInitialState = {
  getDataTableFetch: false,
  getDataTableResponse: [],
  getDataTableParams: {},
  getDataTableError: {
    message: '',
  },
  firebaseData: {}
};
