import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import {
  updateUser as updateUserProps,
  detailUser as detailUserProps,
  uploadImage as uploadImageProps,
  deleteUserPermission as deleteUserPermissionProps,
  getUserPermissionList as getUserPermissionListProps,
  getUserRoles as getUserRolesProps
} from '@myrepublic-users/usersAction';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function DetailCustomerJourney(props) {
  const {
    updateUser,
    updateUserResponse,
    detailUser,
    detailUserResponse,
    deleteUserPermissionResponse,
    deleteUserPermission,
    getUserPermissionList,
    getUserPermissionListResponse,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    getUserRoles, 
    getUserRolesResponse,
    icon,
    
    match: { params },
  } = props;
  const [data, setData] = useState({});
  const [userPermissions, setUserPermissions] = useState({ data: [] });
  const [permissionOptions, setPermissionOptions] = useState([]);
  const history = useHistory();
  const [roleId, setRoleId] = useState();


  useEffect(() => {
    if (detailUserResponse?.code === 200) {
      setData(detailUserResponse?.data);
      detailUserResponse?.data?.user.userPermissions?.map((i, index) => {
        userPermissions?.data.push({id: i.id, permissionId: i.permission.id});
      });
      console.log(detailUserResponse?.data, 'log>>');
      // setItem({ data: detailUserResponse?.addOnItems });
    }
  }, [detailUserResponse]);

  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex((i) => i.name === "Customer Journey");
    setRoleId(getUserRolesResponse?.data?.content[index]?.id);
  }, [getUserRolesResponse])

  useEffect(() => {
    setUserPermissions({ data: [] });
    detailUser({ id: params?.id });
    getUserPermissionList();
    getUserRoles();
  }, []);

  useEffect(() => {
    if (getUserPermissionListResponse?.code === 200) {
      const _permissionOptions = [];
      getUserPermissionListResponse?.data?.map((value, index) => {
        _permissionOptions.push(value);
      });
      setPermissionOptions(_permissionOptions);
      console.log('_permissionOptions >> ', _permissionOptions);
    }
  }, [getUserPermissionListResponse]);

  const ItemListComponent = () => {

    const onChangeUserPermission = (e, index) => {
      const { value } = e.target;
        console.log("checked", value);
        console.log("user permission", userPermissions);
        if (value !== 'initial') {
          if(checkedValue(value) === -1){
            const newArray = [...userPermissions.data];
            newArray.push({permissionId: Number(value)});
            setUserPermissions({ data: newArray });
          } else {
            console.log("here");
            const newArray = [...userPermissions.data];
            newArray.splice(index, 1);
            setUserPermissions({data: newArray})
          }
        }
    };


    const checkedValue = (value) => {
        const index = userPermissions?.data.findIndex((i) => i.permissionId == value)
        return index;
    }

    return (
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Hak Akses</Form.Label>
        {permissionOptions?.map((value, index) => {
            // console.log("value checkbox >>> ", value)
            return (<Form.Check 
              type={'checkbox'}
              id={`permission-${index}`}
              value={value.value}
              disabled={true}
              label={value.label}
              checked={checkedValue(value.value) === -1? false : true}
              onChange={(e) => onChangeUserPermission(e, index)}
            />)
          })}
      </Form.Group>
    );
  };
  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle><h4>Detail Cutomer Journey</h4></CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <h6>Information Detail</h6>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.user?.username}
                  type="text"
                  placeholder="Username"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  value={''}
                  disabled
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={data?.user?.name}
                  disabled={true}
                  type="text"
                  placeholder="Nama"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Handphone</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.user?.handphone}
                  type="text"
                  placeholder="Handphone"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.user?.email}
                  type="email"
                  placeholder="Email"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="maxQueueChat">
                <Form.Label>Max Queue Chat</Form.Label>
                <Form.Control
                  value={data?.user?.maxQueueChat}
                  disabled={true}
                  type="number"
                  placeholder="Max Queue Chat"
                  onChange={(e) => {
                    let value= Number(e.target.value)
                    setData({ ...data, maxQueueChat: value })
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <h6>Chat Summary</h6>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="conversationDuration">
                <Form.Label>Conversation Duration</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.chatSummary?.conversationDuration}
                  type="text"
                  placeholder="Coversation Duration"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="totalCustomersServed">
                <Form.Label>Total Customers Served</Form.Label>
                <Form.Control
                  value={data?.chatSummary?.totalCustomersServed}
                  disabled={true}
                  type="number"
                  placeholder="Total Customers Served"
                  onChange={(e) => {
                    let value= Number(e.target.value)
                    setData({ ...data, maxQueueChat: value })
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="replyDuration">
                <Form.Label>Reply Duration</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.chatSummary?.replyDuration}
                  type="text"
                  placeholder="Reply Duration"
                />
              </Form.Group>
            </Col>
          </Row>
          {ItemListComponent()}
          
        </Form>
      </CardBody>
      {/* <CardFooter>
        <Button
          className="bg-primary"
          onClick={handleOnSave}
          disabled={updateUserResponse.code === 200 ? true : false}>
          Edit
        </Button>
      </CardFooter> */}
    </Card>
  );
}
const mapStateToProps = (state) => ({
  updateUserResponse: state.user.updateUserResponse,
  detailUserResponse: state.user.detailUserResponse,
  deleteUserPermissionResponse: state.user.deleteUserPermissionResponse,
  getUserPermissionListResponse: state.user.getUserPermissionListResponse,
  uploadImageResponse: state.user.uploadImageResponse,
  uploadImageError: state.user.uploadImageError,
  icon: state.product.icon,
  getUserRolesResponse: state.user.getUserRolesResponse,
});
const mapDispatchToProps = {
  updateUser: (payload) => updateUserProps(payload),
  detailUser: (payload) => detailUserProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  deleteUserPermission: (payload) => deleteUserPermissionProps(payload),
  getUserPermissionList: (payload) => getUserPermissionListProps(payload),
  getUserRoles: (payload) => getUserRolesProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCustomerJourney);
