import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import {
  postUsers,
  teknisiVendor,
  putUserStatus,
  createUser,
  updateUser,
  deleteUserPermission,
  getUserPermissionList,
  detailUser,
  uploadDataImage,
  getUserRoles,
  getTechnicianRating
} from '@myrepublic-users/usersApi';
import {
  postUsersFailed,
  postUsersSuccess,
  teknisiVendorFailed,
  teknisiVendorSuccess,
  putUserStatusFailed,
  putUserStatusSuccess,
  createUserFailed,
  createUserSuccess,
  updateUserFailed,
  updateUserSuccess,
  deleteUserPermissionFailed,
  deleteUserPermissionSuccess,
  getUserPermissionListFailed,
  getUserPermissionListSuccess,
  detailUserFailed,
  detailUserSuccess,
  uploadImageFailed,
  uploadImageSuccess,
  setImageUrl,
  getUserRolesSuccess,
  getUserRolesFailed,
  getTechnicianRatingSuccess,
  getTechnicianRatingFailed
} from '@myrepublic-users/usersAction';
import * as CONST from '@myrepublic-users/usersConstant';

function* postUsersSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postUsers, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postUsersSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postUsersFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postUsersFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postUsersFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postUsersFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postUsersFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postUsersFailed(error.response));
        break;
      default:
    }
  }
}

function* teknisiVendorSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(teknisiVendor, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(teknisiVendorSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(teknisiVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(teknisiVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(teknisiVendorFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(teknisiVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(teknisiVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(teknisiVendorFailed(error.response));
        break;
      default:
    }
  }
}
function* putUserStatusSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putUserStatus, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putUserStatusSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putUserStatusFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putUserStatusFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putUserStatusFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putUserStatusFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putUserStatusFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putUserStatusFailed(error.response));
        break;
      default:
    }
  }
}

function* createUserSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(createUser, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(createUserSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(createUserFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(createUserFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(createUserFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(createUserFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(createUserFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(createUserFailed(error.response));
        break;
      default:
    }
  }
}

function* updateUserSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(updateUser, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(updateUserSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(updateUserFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(updateUserFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(updateUserFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(updateUserFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(updateUserFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(updateUserFailed(error.response));
        break;
      default:
    }
  }
}

function* deleteUserPermissionSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(deleteUserPermission, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(deleteUserPermissionSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(deleteUserPermissionFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(deleteUserPermissionFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(deleteUserPermissionFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(deleteUserPermissionFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(deleteUserPermissionFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(deleteUserPermissionFailed(error.response));
        break;
      default:
    }
  }
}

function* getUserPermissionListSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getUserPermissionList, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getUserPermissionListSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getUserPermissionListFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getUserPermissionListFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getUserPermissionListFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getUserPermissionListFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getUserPermissionListFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getUserPermissionListFailed(error.response));
        break;
      default:
    }
  }
}

function* detailUserSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(detailUser, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(detailUserSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(detailUserFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(detailUserFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(detailUserFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(detailUserFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(detailUserFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(detailUserFailed(error.response));
        break;
      default:
    }
  }
}

function* uploadImageSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(uploadDataImage, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(uploadImageSuccess(response.data.data));
        yield put(setImageUrl(response.data.data.url));
        break;
      default:
        yield put(uploadImageFailed(response.data));
        break;
    }
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case RESPONSE_STATUS.UNAUTHORIZED:
          break;
        default:
          yield put(uploadImageFailed(error.response.data));
          break;
      }
    } else {
      yield put(uploadImageFailed(error.response.data));
    }
  }
}

function* getUserRolesSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getUserRoles, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getUserRolesSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getUserRolesFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getUserRolesFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getUserRolesFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getUserRolesFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getUserRolesFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getUserRolesFailed(error.response));
        break;
      default:
    }
  }
}

function* getTechnicianRatingSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getTechnicianRating, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getTechnicianRatingSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getTechnicianRatingFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getTechnicianRatingFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getTechnicianRatingFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getTechnicianRatingFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getTechnicianRatingFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getTechnicianRatingFailed(error.response));
        break;
      default:
    }
  }
}

export default [
  takeLatest(CONST.POST_USERS, postUsersSaga),
  takeLatest(CONST.TEKNISI_VENDOR, teknisiVendorSaga),
  takeLatest(CONST.PUT_USER_STATUS, putUserStatusSaga),
  takeLatest(CONST.CREATE_USER, createUserSaga),
  takeLatest(CONST.UPDATE_USER, updateUserSaga),
  takeLatest(CONST.DELETE_USER_PERMISSION, deleteUserPermissionSaga),
  takeLatest(CONST.GET_USER_PERMISSION_LIST, getUserPermissionListSaga),
  takeLatest(CONST.DETAIL_USER, detailUserSaga),
  takeLatest(CONST.UPLOAD_IMAGE, uploadImageSaga),
  takeLatest(CONST.GET_USER_ROLES, getUserRolesSaga),
  takeLatest(CONST.GET_TECHNICIAN_RATING, getTechnicianRatingSaga)
];
