import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Paper, makeStyles, Grid, Snackbar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DatatableServer from '../../../components/organisms/datatable';
import { DropzoneArea } from 'material-ui-dropzone';
import Alert from '@material-ui/lab/Alert';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  getAward as getAwardProps,
  postAward as postAwardProps,
  deleteAward as deleteAwardProps,
  putAward as putAwardProps,
  uploadImage as uploadImageProps,
} from '@myrepublic-awards/awardAction';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';
import { Button, Form, Modal } from 'react-bootstrap';
import { columns } from './column';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  container: {
    maxHeight: 640,
  },
}));

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CreateModal(props) {
  const {
    show,
    handleClose,
    onChangeText,
    onChangeImage,
    onChangeDescription,
    handleSave,
    uploadImage,
    closeToast,
    openErrorInfo,
    errorInfo,
    key,
  } = props;
  return (
    <Modal show={show} onHide={handleClose} className="modal-data">
      <Modal.Header closeButton>
        <Modal.Title>Tambah Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nama</Form.Label>
            <Form.Control type="text" onChange={onChangeText} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Gambar</Form.Label>
            <Snackbar
              open={openErrorInfo}
              autoHideDuration={4000}
              onClose={closeToast}>
              <Alert
                onClose={closeToast}
                severity="error"
                sx={{ width: '100%' }}>
                {errorInfo}
              </Alert>
            </Snackbar>
            <div className="dropZone">
              <DropzoneArea
                filesLimit={1}
                maxFileSize={3000000}
                onChange={uploadImage}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                dropzoneText={
                  'Drag and drop gambar atau klik disini untuk mengupload'
                }
                showAlerts={false}
                showFileNames={true}
                key={key}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Deskripsi</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              // config={ {
              //   plugins: [ Alignment ],
              // } }
              data=""
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log('Editor event data');
                // console.log({ event, editor, data });
                onChangeDescription(data);
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Batal
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function UpdateModal(props) {
  const {
    show,
    handleClose,
    onChangeText,
    onChangeDescription,
    handleSave,
    dataAward,
    uploadImage,
    closeToast,
    openErrorInfo,
    errorInfo,
    key,
  } = props;
  return (
    <Modal show={show} onHide={handleClose} className="modal-data">
      <Modal.Header closeButton>
        <Modal.Title>Update Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nama</Form.Label>
            <Form.Control
              type="text"
              value={dataAward?.name}
              onChange={onChangeText}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Card>
              <CardMedia component="img" height="194" image={dataAward?.url} />
            </Card>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Update Gambar</Form.Label>
            <Snackbar
              open={openErrorInfo}
              autoHideDuration={4000}
              onClose={closeToast}>
              <Alert
                onClose={closeToast}
                severity="error"
                sx={{ width: '100%' }}>
                {errorInfo}
              </Alert>
            </Snackbar>
            <div className="dropZone">
              <DropzoneArea
                filesLimit={1}
                maxFileSize={3000000}
                onChange={uploadImage}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                dropzoneText={
                  'Drag and drop gambar atau klik disini untuk mengupload'
                }
                showAlerts={false}
                showFileNames={true}
                key={key}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Deskripsi</Form.Label>
            {/*<Form.Control
              as="textarea"
              rows={3}
              value={dataAward?.description}
              onChange={onChangeDescription}
            />*/}
            <CKEditor
              editor={ClassicEditor}
              data={dataAward?.description}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log('Editor event data');
                // console.log({ event, editor, data });
                onChangeDescription(data);
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Batal
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Awards(props) {
  const {
    getAward,
    getAwardsResponse,
    postAward,
    deleteAward,
    deleteAwardResponse,
    postAwardResponse,
    putAwardResponse,
    putAward,
    pagination,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    url,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [onGetData, setOnGetData] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const [deleteParam, setDeleteParam] = useState({
    name: '',
    url: '',
    description: '',
  });
  const [editParam, setEditParam] = useState({
    name: '',
    url: '',
    description: '',
  });
  const [data, setData] = useState({
    name: '',
    url: '',
    description: '',
  });
  const getDataTable = () => {
    const initialLimit = {
      limit: 10,
      offset: 0,
    };
    getAward({ ...pagination });
  };

  const toogleModal = (row, data) => {
    if (data?.name === 'delete') {
      handleDelete(row);
    } else if (data?.name === 'update') {
      handleEdit(row);
    }
  };
  const handleOpenErrorInfo = () => {
    setOpenErrorInfo(true);
  };
  const handleClose = () => {
    setShowCreateModal(false);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    console.log("delete",deleteParam?.data?.id)
    deleteAward({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };

  const modalShowData = () => {
    setShowCreateModal(true);
  };

  const validateCreateForm = () => {
    if (data?.name === '') {
      setErrorInfo('Nama harus diisi');
      handleOpenErrorInfo();
      return false;
    } else if (data?.url === '') {
      setErrorInfo('Gambar harus diupload');
      handleOpenErrorInfo();
      return false;
    } else if (data?.description === '') {
      setErrorInfo('Deskripsi harus diisi');
      handleOpenErrorInfo();
      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    if (validateCreateForm() === true) {
      postAward({ ...data });
      setData({ name: '', url: '', description: '' });
      setShowCreateModal(false);
      getDataTable();
    }
  };
  
  const handleDelete = (row) => {
    setDeleteParam({
      url: row?.url,
      name: row?.name,
      description: row?.description,
      data: row,
    });
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    setEditParam({
      url: row?.url,
      name: row?.name,
      description: row?.description,
      data: row,
    });
    setOpenModalEdit(true);
  };
  const onUploadImage = (image) => {
    if (image?.length > 0) {
      uploadImage({ data: { image: image[0], type: 'AWARD' } });
    }
  };

  const validateUpdateForm = () => {
    if (editParam?.name === '') {
      setErrorInfo('Nama harus diisi');
      handleOpenErrorInfo();
      return false;
    } else if (editParam?.url === '') {
      setErrorInfo('Gambar harus diupload');
      handleOpenErrorInfo();
      return false;
    } else if (editParam?.description === '') {
      setErrorInfo('Deskripsi harus diisi');
      handleOpenErrorInfo();
      return false;
    }
    return true;
  };

  const onUpdateData = () => {
    if (validateUpdateForm() === true) {
      const editData = {
        name: editParam?.name,
        url: editParam?.url,
        description: editParam?.description,
      };
      putAward({ id: editParam?.data?.id, ...editData });
      setOpenModalEdit(false);
    }
  };
  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={modalShowData}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };
  useEffect(() => {
    getDataTable();
    setDeleteParam({});
  }, [deleteAwardResponse, postAwardResponse, putAwardResponse, pagination]);

  useEffect(() => {
    if (uploadImageResponse?.url !== undefined) {
      setData({ ...data, url: uploadImageResponse?.url });
      setEditParam({ ...editParam, url: uploadImageResponse?.url });
    }
  }, [uploadImageResponse]);

  useEffect(() => {
    if (uploadImageError?.error !== undefined && uploadImageError?.message) {
      setErrorInfo(uploadImageError?.message);
      handleOpenErrorInfo();
      setKey();
    }
  }, [uploadImageError]);

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={getAwardsResponse?.totalElements}
        totalPage={pagination.limit}
        wrapTitle={'Award'}
        data={getAwardsResponse?.content}
        columns={columns(toogleModal)}
        withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
        <CreateModal
          show={showCreateModal}
          handleClose={handleClose}
          handleSave={handleOnSave}
          onChangeText={(e) => setData({ ...data, name: e.target.value })}
          onChangeDescription={(e) => {
            setData({ ...data, description: e });
          }}
          uploadImage={onUploadImage}
          closeToast={closeToast}
          openErrorInfo={openErrorInfo}
          errorInfo={errorInfo}
          key={debounceKey}
        />
        <UpdateModal
          show={openModalEdit}
          handleClose={() => setOpenModalEdit(!openModalEdit)}
          handleSave={onUpdateData}
          dataAward={editParam}
          onChangeText={(e) =>
            setEditParam({ ...editParam, name: e.target.value })
          }
          onChangeDescription={(e) =>
            setEditParam({ ...editParam, description: e })
          }
          uploadImage={onUploadImage}
          closeToast={closeToast}
          openErrorInfo={openErrorInfo}
          errorInfo={errorInfo}
          key={debounceKey}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  getAwardsResponse: state.award.getAwardsResponse,
  deleteAwardResponse: state.award.deleteAwardResponse,
  postAwardResponse: state.award.postAwardResponse,
  putAwardResponse: state.award.putAwardResponse,
  uploadImageResponse: state.award.uploadImageResponse,
  uploadImageError: state.award.uploadImageError,
  url: state.award.url,
  pagination: state.paging.pagination,
});

const mapDispatchToProps = {
  getAward: (payload) => getAwardProps(payload),
  postAward: (payload) => postAwardProps(payload),
  deleteAward: (payload) => deleteAwardProps(payload),
  putAward: (payload) => putAwardProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(Awards);
