import * as CONST from '@myrepublic-bootstrap/bootstrapContant';

export const PaginationHandler = (payload) => ({
  type: CONST.SET_PAGINATION,
  payload,
});

export const getDataTable = (payload) => ({
  type: CONST.GET_DATA_TABLE,
  payload,
});

export const getDataTableSuccess = (payload) => ({
  type: CONST.GET_DATA_TABLE_SUCCESS,
  payload,
});

export const getDataTableFailed = (payload) => ({
  type: CONST.GET_DATA_TABLE_FAILED,
  payload,
});

export const setFirebaseData = (payload) => ({
  type: CONST.SET_FIREBASE,
  payload,
});
