import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  postUsers as postUsersProps,
  putUserStatus as putUserStatusProps,
  getUserRoles as getUserRolesProps,
  resetState as resetStateProps,
  postUsersReset as postUsersResetProps,
} from '@myrepublic-users/usersAction';
import { columns } from './column';
import { useHistory } from 'react-router';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SuperAdmin(props) {
  const {
    pagination,
    postUsers,
    postUsersReset,
    postUsersResponse,
    putUserStatus,
    putUserStatusResponse,
    resetState,
    getUserRoles,
    getUserRolesResponse,
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [roleId, setRoleId] = useState();
  const history = useHistory();

  const [editParam, setEditParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });

  const [deleteParam, setDeleteParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });

  const getDataTable = (params) => {
    params
      ? postUsers({
          data: {
            limit: 10,
            offset: 0,
            load: false,
            filter: { roleName: 'Superadmin' },
          },
        })
      : postUsers({
          data: { ...pagination, filter: { roleName: 'Superadmin' } },
        });
  };

  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex(
      (i) => i.name === 'Superadmin'
    );
    setRoleId(getUserRolesResponse?.data?.content[index]?.id);
  }, [getUserRolesResponse]);

  useEffect(() => {
    postUsersReset();
    getUserRoles();
  }, []);

  const prevPaging = usePrevious(pagination);

  useEffect(() => {
    if (!prevPaging) {
      getDataTable(true);
    } else {
      getDataTable(false);
    }
  }, [pagination]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // const handleOpenErrorInfo = () => {
  //   setOpenErrorInfo(true);
  // };
  // const handleClose = () => {
  //   setShowCreateModal(false);
  // };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    // deleteProduct({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };
  const handleDelete = (row) => {
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    resetState({});

    setEditParam({
      password: row?.password,
      name: row?.password,
      roleId: roleId,
      profileImageUrl: row?.profileImageUrl,
      devicePackageName: 'WEB',
      userPermissionList: row?.userPermissions,
    });
    history.push('/update-superadmin/' + row?.id);
  };

  const handleActive = (row) => {
    putUserStatus({ id: row.id, payload: { active: !row.active } });
  };

  const modalShowData = () => {
    // setShowCreateModal(true);
    // history.push('/create-product');
    history.push('/create-superadmin/');
  };

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={modalShowData}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };
  // useEffect(() => {
  //   getDataTable();
  // }, [deleteProductResponse, pagination]);

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={postUsersResponse?.data?.totalElements || 0}
        totalPage={pagination.limit}
        data={postUsersResponse?.data?.content}
        wrapTitle={'Superadmin'}
        columns={columns(handleEdit, handleDelete, handleActive)}
        withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postUsersResponse: state.user.postUsersResponse,
  putUserStatusResponse: state.user.putUserStatusResponse,
  getUserRolesResponse: state.user.getUserRolesResponse,
});

const mapDispatchToProps = {
  postUsers: (payload) => postUsersProps(payload),
  postUsersReset: (payload) => postUsersResetProps(payload),
  putUserStatus: (payload) => putUserStatusProps(payload),
  resetState: () => resetStateProps(),
  getUserRoles: (payload) => getUserRolesProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(SuperAdmin);
