import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { columns } from './column';
import { useHistory } from 'react-router';
import { getWorkOrder as getWorkOrderProps } from '@myrepublic-wokumo/wokumoAction'

// function OpenConfirm(props) {
//   const { 
//     handleClose, 
//     handleClickOpen,
//     open 
//   } = props;

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description">
//         <DialogTitle id="alert-dialog-title">
//           {'Konfirmasi hapus data'}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Apakah anda yakin akan menghapus data?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Tidak
//           </Button>
//           <Button onClick={handleClickOpen} color="primary" autoFocus>
//             Ya
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

function WorkOrder(props) {
  const {
    getWorkOrder,
    getWorkOrderResponse,
    pagination,
  } = props;
//   const [showCreateModal, setShowCreateModal] = useState(false);
//   const [openModalDelete, setOpenModalDelete] = useState(false);
//   const [openErrorInfo, setOpenErrorInfo] = useState(false);
//   const [totalData, setTotalData] = useState();
//   const [roleId, setRoleId] = useState();
  const history = useHistory();

//   const [deleteParam, setDeleteParam] = useState({
//     name: '',
//     code: '',
//     icon: '',
//     description: '',
//     addOnItemListName: '',
//     addOnItemListPrice: '',
//     tv: false,
//   });

  const getDataTable = () => {
    getWorkOrder({...pagination});
  };

  useEffect(() => {
    getDataTable();
  }, [pagination])

//   useEffect(() => {
//     setTotalData(postUsersResponse?.totalElements || 0)
//   }, [postUsersResponse])

  useEffect(() => {
    getDataTable();
  }, [])

//   useEffect(() => {
//     const index = getUserRolesResponse?.data?.content?.findIndex((i) => i.name === "Customer");
//     setRoleId(getUserRolesResponse?.data?.content[index]?.id);
//   }, [getUserRolesResponse])

//   useEffect(() => {
//     getUserRoles();
//   }, []);

//   useEffect(() => {
//     getDataTable();
//   }, [putUserStatusResponse]);

//   const handleCloseModalDelete = () => {
//     setOpenModalDelete(false);
//   };
//   const handleOpenModalDelete = () => {
//     // deleteProduct({ id: deleteParam?.data?.id });
//     setOpenModalDelete(false);
//   };

  const handleActive = (row) => {
    // putUserStatus({id: row.id, payload: {active: !row.active}})
    console.log('row is', row)
  }

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    // resetState({});
    // console.log('handleEdit');
    // history.push('/update-customer/' + row?.id);
  };

  const modalShowData = () => {
    history.push('/create-work-order');
  };

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={() => history.push('/create-work-order')}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };

  return (
    <>

      <WrapContainerBased
        withTable
        withPagination
        totalRecord={getWorkOrderResponse?.totalElements || 0}
        totalPage={pagination.limit}
        wrapTitle={'Work Order'}
        data={getWorkOrderResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        {/* <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        /> */}
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
//   postUsersResponse: state.user.postUsersResponse,
//   putUserStatusResponse: state.user.putUserStatusResponse,
//   getUserRolesResponse: state.user.getUserRolesResponse,
  pagination: state.paging.pagination,
  getWorkOrderResponse: state.wokumo.getWorkOrderResponse
});

const mapDispatchToProps = {
//   postUsers: (payload) => postUsersProps(payload),
//   putUserStatus: (payload) => putUserStatusProps(payload),
//   resetState: () => resetStateProps(),
//   getUserRoles: (payload) => getUserRolesProps(payload),
  getWorkOrder: (payload) => getWorkOrderProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);

