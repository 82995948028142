import React, { useState } from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import {
  Dashboard,
  ShoppingCart,
  Build,
  ChromeReaderMode,
  Stars,
  BarChart,
  ChatBubble,
  GroupAdd,
  Settings,
  Storefront,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAllowed } from '@myrepublic-utils';
import './styles.scss';

function ListItemLink(props) {
  return <ListItem className="hover-event" button component="a" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  itemIcon: {
    padding: '0 6px',
  },
}));
// const menus = [
//   {
//     index: 0,
//     path: '/',
//     icon: <Dashboard />,
//     name: 'Dashboard',
//     access: '',
//   },
//   {
//     index: 1,
//     path: '/awards',
//     icon: <Stars />,
//     name: 'Awards',
//     access: '/api/v1/web/awards_READ',
//   },
//   {
//     index: 2,
//     path: '#',
//     icon: <ShoppingCart />,
//     name: 'Products',
//     access: '/api/v1/web/addOnProducts_READ',
//     subMenu: [
//       {
//         index: 21,
//         name: 'Add On',
//         path: '/products',
//         access: '/api/v1/web/addOnProducts_READ',
//       },
//       {
//         index: 22,
//         name: 'TV',
//         path: '/productsTv',
//         access: '/api/v1/web/addOnTv_READ',
//       },
//     ],
//   },/*
//   {
//     index: 3,
//     path: '/package',
//     icon: <BarChart />,
//     name: 'Plan/Package',
//     access: '',
//   },
//   {
//     index: 4,
//     path: '/storyboard',
//     icon: <ChromeReaderMode />,
//     name: 'Kuis Storyboard',
//     access: '',
//   },*/
//   {
//     index: 3,
//     path: '/troubleshooting',
//     icon: <Build />,
//     name: 'Troubleshooting',
//     access: '/api/v1/web/troubleshootings_READ',
//   },
//   {
//     index: 4,
//     path: '/livechat',
//     icon: <ChatBubble />,
//     name: 'Live Chat',
//     access: '/api/v1/web/chat_READ',
//   },
//   {
//     index: 5,
//     path: '#',
//     icon: <GroupAdd />,
//     name: 'User',
//     access: '/api/v1/web/users_READ',
//     subMenu: [
//       {
//         index: 51,
//         name: 'Customer',
//         path: '/customers',
//         access: '/api/v1/web/users_READ',
//       },
//       {
//         index: 52,
//         name: 'Teknisi',
//         path: '/teknisi',
//         access: '/api/v1/web/users_READ',
//       },
//       {
//         index: 53,
//         name: 'Super Admin',
//         path: '/superadmin',
//         access: '/api/v1/web/users_READ',
//       },
//       {
//         index: 54,
//         name: 'Customer Journey',
//         path: '/customer-journey',
//         access: '/api/v1/web/users_READ',
//       },
//     ],
//   },
//   {
//     index: 6,
//     path: '/user-permissions',
//     icon: <Settings />,
//     name: 'Hak Akses',
//     access: '/api/v1/web/user-permissions_READ',
//   },
// ];

const menus = [
  {
    id: 1,
    path: '#',
    icon: <GroupAdd />,
    name: 'Customer',
    subMenu: [
      {
        id: 11,
        path: '/awards',
        icon: <Stars />,
        name: 'Awards',
        access: '/api/v1/web/awards_READ',
      },
      {
        id: 12,
        path: '#',
        icon: <ShoppingCart />,
        name: 'Products',
        access: '/api/v1/web/addOnProducts_READ',
        subMenu: [
          {
            id: 121,
            name: 'Add On',
            path: '/products',
            access: '/api/v1/web/addOnProducts_READ',
          },
          {
            id: 122,
            name: 'TV',
            path: '/productsTv',
            access: '/api/v1/web/addOnTv_READ',
          },
        ],
      },
      {
        id: 13,
        path: '/troubleshooting',
        icon: <Build />,
        name: 'Troubleshooting',
        access: '/api/v1/web/troubleshootings_READ',
      },
      {
        id: 14,
        path: '/livechat',
        icon: <ChatBubble />,
        name: 'Live Chat',
        access: '/api/v1/web/chat_READ',
        subMenu: [
          {
            id: 141,
            path: '/livechat-setting',
            name: 'Setting',
            access: '/api/v1/web/chatSetting_READ',
          },
          {
            id: 142,
            path: '/livechat-statistik',
            name: 'Statistik',
            access: '/api/v1/web/chat_READ',
          },
          {
            id: 143,
            path: '/livechat-monitoring',
            name: 'Monitoring',
            access: '/api/v1/web/chatSetting_READ',
          },
          {
            id: 144,
            path: '/livechat-history',
            name: 'History',
            access: '/api/v1/web/chat_READ',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    path: '#',
    icon: <Storefront />,
    name: 'Wokumo',
    access: '/api/v1/web/vendors_READ',
    subMenu: [
      {
        id: 21,
        path: '/vendor',
        name: 'Vendor',
        access: '/api/v1/web/vendors_READ',
      },
      {
        id: 22,
        path: '/work-order',
        name: 'Work Order',
        access: '/api/v1/web/vendors_READ',
      },
      {
        id: 23,
        name: 'Teknisi',
        path: '/teknisi',
        access: '/api/v1/web/vendors_READ',
      },
      {
        id: 24,
        name: 'Vendor Monitoring',
        path: '/monitoring-vendor',
        access: '/api/v1/web/vendors_READ',
      },
    ],
  },
  {
    id: 3,
    path: '#',
    icon: <Settings />,
    name: 'Hak Akses',
    access: '/api/v1/web/user-permissions_READ',
    subMenu: [
      {
        id: 31,
        path: '#',
        icon: <GroupAdd />,
        name: 'User',
        access: '/api/v1/web/users_READ',
        subMenu: [
          {
            id: 311,
            name: 'Customer',
            path: '/customers',
            access: '/api/v1/web/users_READ',
          },
          {
            id: 312,
            name: 'Teknisi',
            path: '/teknisi',
            access: '/api/v1/web/users_READ',
          },
          {
            id: 313,
            name: 'Super Admin',
            path: '/superadmin',
            access: '/api/v1/web/users_READ',
          },
          {
            id: 314,
            name: 'Customer Journey',
            path: '/customer-journey',
            access: '/api/v1/web/users_READ',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    path: '#',
    icon: <GroupAdd />,
    name: 'Teknisi Vendor',
    subMenu: [
      {
        id: 1,
        name: 'Teknisi',
        path: '/teknisi-vendor',
        access: '/api/v1/web/teknisiVendor_READ',
      },
    ],
  },
];
const Sidebar = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();
  //   const [isActive, setIsActive] = useState(0);
  //   const [isActiveSubMenu, setIsActiveSubMenu] = useState();
  const session = useSelector((state) => state.session);

  const [currentActivePage, setCurrentActivePage] = useState({
    first: null,
    second: null,
    third: null,
  });

  const toggleSubMenu = (key, param) => {
    if (currentActivePage[key] !== null) {
      setCurrentActivePage({ ...currentActivePage, [key]: null });
    } else {
      setCurrentActivePage({ ...currentActivePage, [key]: param.id });
    }
    if (param.path !== '#') handleClick(key, param);
  };

  const handleClick = (key, param) => {
    setCurrentActivePage({ ...currentActivePage, [key]: param.id });
    history.push(param.path);
  };

  const SecondListItem = ({ secondSubMenu }) => {
    console.log('currentACtivePage', currentActivePage);
    return (
      <div>
        <div
          style={{
            paddingLeft: 50,
            backgroundColor:
              currentActivePage.second === secondSubMenu.id ? '#CFCFCF' : '',
            // color: currentActivePage.second === secondSubMenu.id ? 'white' : '',
          }}>
          <ListItemLink
            onClick={() => {
              secondSubMenu?.subMenu?.length > 0
                ? toggleSubMenu('second', secondSubMenu)
                : handleClick('second', secondSubMenu);
            }}>
            <ListItemText className="hover">
              {' '}
              {secondSubMenu.name}{' '}
            </ListItemText>
            <ListItemIcon className={classes.itemIcon}>
              {secondSubMenu?.subMenu ? (
                currentActivePage.second === secondSubMenu.id ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )
              ) : null}
            </ListItemIcon>
          </ListItemLink>
        </div>
        {secondSubMenu?.subMenu && currentActivePage.second === secondSubMenu.id
          ? secondSubMenu?.subMenu?.map((thirdSubMenu) =>
              isAllowed(session, thirdSubMenu?.access) ||
              thirdSubMenu?.access === '' ? (
                <ThirdListItem thirdSubMenu={thirdSubMenu} />
              ) : null
            )
          : null}
      </div>
    );
  };

  const ThirdListItem = ({ thirdSubMenu }) => {
    return (
      <div
        style={{
          paddingLeft: 50,
          // backgroundColor: currentActivePage.third === thirdSubMenu.id ? '#CFCFCF' : '',
          color: currentActivePage.third === thirdSubMenu.id ? '#AB28C7' : '',
        }}>
        <ListItemLink onClick={() => handleClick('third', thirdSubMenu)}>
          <ListItemText className="hover">
            <div
              style={{
                fontWeight:
                  currentActivePage.third === thirdSubMenu.id
                    ? 'bold'
                    : 'normal',
              }}>
              {thirdSubMenu.name}
            </div>
          </ListItemText>
        </ListItemLink>
      </div>
    );
  };

  return (
    <List>
      {menus.map((param, index) => {
        if (isAllowed(session, param?.access, param) || param?.access === '') {
          return (
            <div>
              <ListItemLink
                onClick={() => {
                  param?.subMenu?.length > 0
                    ? toggleSubMenu('first', param)
                    : handleClick('first', param);
                }}
                style={{
                  backgroundColor:
                    currentActivePage.first === param.id ? '#AB28C7' : '',
                  color: currentActivePage.first === param.id ? 'white' : '',
                }}>
                <ListItemIcon className={classes.itemIcon}>
                  {param.icon}
                </ListItemIcon>
                <ListItemText className="hover"> {param.name} </ListItemText>
                <ListItemIcon className={classes.itemIcon}>
                  {param?.subMenu ? (
                    currentActivePage.first === param.id ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowRight />
                    )
                  ) : null}
                </ListItemIcon>
              </ListItemLink>
              {param?.subMenu && currentActivePage.first === param.id
                ? param?.subMenu?.map((secondSubMenu) =>
                    isAllowed(session, secondSubMenu?.access) ||
                    secondSubMenu?.access === '' ? (
                      <SecondListItem secondSubMenu={secondSubMenu} />
                    ) : null
                  )
                : null}
            </div>
          );
        }
      })}
    </List>
  );
};

export default Sidebar;
