import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import {
  updateUser as updateUserProps,
  detailUser as detailUserProps,
  uploadImage as uploadImageProps,
  deleteUserPermission as deleteUserPermissionProps,
  getUserPermissionList as getUserPermissionListProps,
  getUserRoles as getUserRolesProps,
  resetState as resetStateProps,
} from '@myrepublic-users/usersAction';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Rating from './rating';

function DetailTeknisi(props) {
  const {
    updateUser,
    updateUserResponse,
    detailUser,
    detailUserResponse,
    deleteUserPermissionResponse,
    deleteUserPermission,
    getUserPermissionList,
    getUserPermissionListResponse,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    getUserRoles,
    getUserRolesResponse,
    icon,
    resetState,
    match: { params },
  } = props;
  const [data, setData] = useState({});
  const [description, setDescription] = useState({ value: '' });
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [userPermissions] = useState({ data: [] });
  const [permissionOptions, setPermissionOptions] = useState([]);
  const history = useHistory();
  const [key, setKey] = useState(0);
  const [roleId, setRoleId] = useState();

  //  GET DATA USER, PERMISSION LIST, USER ROLE
  useEffect(() => {
    detailUser({ id: params?.id });
    getUserPermissionList();
    getUserRoles();
  }, []);

  // HANDLE SAVE EDIT
  const handleOnSave = () => {
    if (validateCreateForm() === true) {
      const sendData = {
        name: data.name,
        devicePackageName: 'WOKUMO',
        roleId: roleId,
        userPermissionList: dataVal(),
      };
      updateUser({
        payload: sendData,
        id: data.id,
      });
    }
  };

  const validateCreateForm = () => {
    let userPermissionList = dataVal();
    if (data?.permissionId === '') {
      setErrorInfo('Nama Permission harus diisi');
      // handleOpenErrorInfo();
      return false;
    } else {
      userPermissionList.map((items) => {
        if (
          items.permissionId === undefined ||
          items.permissionId === 'undefined' ||
          items.permissionId === ''
        ) {
          setErrorInfo('Lengkapi semua field data pada permission list');
          // handleOpenErrorInfo();
          return false;
        }
      });
    }
    return true;
  };

  const dataVal = () => {
    const value = userPermissions.data;
    let data = [];
    if (typeof value === 'object') {
      data = value.map((userPermission, index) => {
        if (userPermission.id === undefined) {
          return {
            permissionId: userPermission.permissionId,
          };
        }
        return {
          id: userPermission.id,
          permissionId: userPermission.permissionId,
        };
      });
      return values(data);
    }
  };

  // UPDATE USER BERHASIL
  useEffect(() => {
    if (updateUserResponse?.code === 200) {
      toast.success(updateUserResponse?.message);
      setTimeout(() => {
        history.replace('/teknisi');
      }, 3000);
    }
    return () => {
      resetState({});
    };
  }, [updateUserResponse]);

  // GET USER PERMISSION
  useEffect(() => {
    if (detailUserResponse?.code === 200) {
      setData(detailUserResponse?.data?.user);
      detailUserResponse?.data?.user?.userPermissions?.map((i, index) => {
        userPermissions?.data.push({ permissionId: i.permission.id });
      });
    }
  }, [detailUserResponse]);

  //  GET USER ROLE ID FOR 'Technician'
  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex(
      (i) => i.name === 'Technician'
    );
    setRoleId(getUserRolesResponse?.data?.content[index]?.id);
  }, [getUserRolesResponse]);

  return (
    <>
      <Card className="mx-4 card-box">
        <CardHeader>
          <CardTitle>
            <h5>Detail Teknisi</h5>
          </CardTitle>
        </CardHeader>
        <CardBody className="p-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    disabled
                    value={data?.username}
                    type="text"
                    placeholder="Username"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    disabled
                    value={data?.password}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    value={data?.name}
                    type="text"
                    placeholder="Nama"
                    disabled={false}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Handphone</Form.Label>
                  <Form.Control
                    disabled
                    value={data?.handphone}
                    type="text"
                    placeholder="Handphone"
                    onChange={(e) =>
                      setData({ ...data, handphone: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    disabled
                    value={data?.email}
                    type="email"
                    placeholder="Email"
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Rating technicianId={params?.id} handleOnSave={handleOnSave} />
    </>
  );
}
const mapStateToProps = (state) => ({
  updateUserResponse: state.user.updateUserResponse,
  detailUserResponse: state.user.detailUserResponse,
  deleteUserPermissionResponse: state.user.deleteUserPermissionResponse,
  getUserPermissionListResponse: state.user.getUserPermissionListResponse,
  uploadImageResponse: state.user.uploadImageResponse,
  uploadImageError: state.user.uploadImageError,
  icon: state.product.icon,
  getUserRolesResponse: state.user.getUserRolesResponse,
});
const mapDispatchToProps = {
  updateUser: (payload) => updateUserProps(payload),
  detailUser: (payload) => detailUserProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  deleteUserPermission: (payload) => deleteUserPermissionProps(payload),
  getUserPermissionList: (payload) => getUserPermissionListProps(payload),
  getUserRoles: (payload) => getUserRolesProps(payload),
  resetState: () => resetStateProps(),
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTeknisi);
