import * as CONST from '@myrepublic-wokumo/wokumoConstant';
import * as STATE from '@myrepublic-wokumo/wokumoInitialState';

const wokumoInitialState = {
  ...STATE.wokumoInitialState,
  action: '',
};

const wokumoReducer = (state = wokumoInitialState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.GET_VENDOR]: () => ({
      ...state,
      getVendorFetch: true,
      getVendorParams: payload,
      action: type,
    }),
    [CONST.GET_VENDOR_SUCCESS]: () => ({
      ...state,
      getVendorFetch: false,
      getVendorResponse: payload,
      action: type,
    }),
    [CONST.GET_VENDOR_FAILED]: () => ({
      ...state,
      getVendorFetch: false,
      getVendorError: payload,
      action: type,
    }),

    [CONST.GET_WORK_ORDER]: () => ({
      ...state,
      getWorkOrderFetch: true,
      getWorkOrderParams: payload,
      action: type,
    }),
    [CONST.GET_WORK_ORDER_SUCCESS]: () => ({
      ...state,
      getWorkOrderFetch: false,
      getWorkOrderResponse: payload,
      action: type,
    }),
    [CONST.GET_WORK_ORDER_FAILED]: () => ({
      ...state,
      getWorkOrderFetch: false,
      getWorkOrderError: payload,
      action: type,
    }),
    [CONST.POST_WORK_ORDER]: () => ({
      ...state,
      postWorkOrderFetch: true,
      postWorkOrderParams: payload,
      action: type,
    }),
    [CONST.POST_WORK_ORDER_SUCCESS]: () => ({
      ...state,
      postWorkOrderFetch: false,
      postWorkOrderResponse: payload,
      action: type,
    }),
    [CONST.POST_WORK_ORDER_FAILED]: () => ({
      ...state,
      postWorkOrderFetch: false,
      postWorkOrderError: payload,
      action: type,
    }),

    [CONST.PUT_VENDOR_STATUS]: () => ({
      ...state,
      putVendorStatusFetch: true,
      putVendorStatusParams: payload,
      action: type,
    }),
    [CONST.PUT_VENDOR_STATUS_SUCCESS]: () => ({
      ...state,
      putVendorStatusFetch: false,
      putVendorStatusResponse: payload,
      action: type,
    }),
    [CONST.PUT_VENDOR_STATUS_FAILED]: () => ({
      ...state,
      putVendorStatusFetch: false,
      putVendorStatusError: payload,
      action: type,
    }),

    [CONST.POST_VENDOR]: () => ({
      ...state,
      postVendorFetch: true,
      postVendorParams: payload,
      action: type,
    }),
    [CONST.POST_VENDOR_SUCCESS]: () => ({
      ...state,
      postVendorFetch: false,
      postVendorResponse: payload,
      action: type,
    }),
    [CONST.POST_VENDOR_FAILED]: () => ({
      ...state,
      postVendorFetch: false,
      postVendorError: payload,
      action: type,
    }),

    [CONST.PUT_VENDOR]: () => ({
      ...state,
      putVendorFetch: true,
      putVendorParams: payload,
      action: type,
    }),
    [CONST.PUT_VENDOR_SUCCESS]: () => ({
      ...state,
      putVendorFetch: false,
      putVendorResponse: payload,
      action: type,
    }),
    [CONST.PUT_VENDOR_FAILED]: () => ({
      ...state,
      putVendorFetch: false,
      putVendorError: payload,
      action: type,
    }),

    [CONST.GET_VENDOR_DETAIL]: () => ({
      ...state,
      getVendorDetailFetch: true,
      getVendorDetailParams: payload,
      action: type,
    }),
    [CONST.GET_VENDOR_DETAIL_SUCCESS]: () => ({
      ...state,
      getVendorDetailFetch: false,
      getVendorDetailResponse: payload,
      action: type,
    }),
    [CONST.GET_VENDOR_DETAIL_FAILED]: () => ({
      ...state,
      getVendorDetailFetch: false,
      getVendorDetailError: payload,
      action: type,
    }),

    [CONST.POST_VENDOR_STATISTIC]: () => ({
      ...state,
      postVendorStatisticsFetch: true,
      postVendorStatisticsParams: payload,
      action: type,
    }),
    [CONST.POST_VENDOR_STATISTIC_SUCCESS]: () => ({
      ...state,
      postVendorStatisticsFetch: false,
      postVendorStatisticsResponse: payload,
      action: type,
    }),
    [CONST.POST_VENDOR_STATISTIC_FAILED]: () => ({
      ...state,
      postVendorStatisticsFetch: false,
      postVendorStatisticsError: payload,
      action: type,
    }),

    [CONST.RESET_STATE]: () => ({
      ...state,
      postVendorDetailResponse: {},
      action: type,
    }),

    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default wokumoReducer;
