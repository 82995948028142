import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from './routes';
import PrivateRoute from './components/organisms/routing';
import theme from './shared/theme';
import Login from './modules/auth/page';
import Unauthorized from './modules/auth/page/Unauthorized';
import Forgot from './modules/forgotPassword/page';
import Resetp from './modules/resetPassword/page';
import Finish from './modules/finishPassword/page';
import './App.less';
import { PersistGate } from 'redux-persist/integration/react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider, useSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import bootstrapSaga from '@myrepublic-bootstrap/bootstrapSaga';
import bootstrapReducer from '@myrepublic-bootstrap/bootstrapReducer';
import { setFirebaseData } from '@myrepublic-bootstrap/bootstrapAction';
import { getMessages } from '@myrepublic-livechat/liveChatAction';
import Persist from '@myrepublic-config/Persist';
// import './scss/style.scss';
import { LayoutBaseContainer } from '@myrepublic-components/container';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';

import { firebaseConfig } from './config/firebase';
import { clearAuth } from '@myrepublic-auth/authAction';
import CustomNotification from '@myrepublic-components/CustomNotification';
import firebase from 'firebase/app';
import 'firebase/database';

// import Dashboard from '@myrepublic-dahsboard/page';
const sagaMiddleware = createSagaMiddleware();
let reducer = bootstrapReducer;
if (Persist.active) {
  const persistConfig = Persist.storeConfig;
  reducer = persistReducer(persistConfig, bootstrapReducer);
}
const composeEnhancer =
  (process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;
const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);

sagaMiddleware.run(bootstrapSaga);
const MyApp = (props) => {
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [notificationInfo, setNotificationInfo] = useState({
    title: '',
    body: '',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((state) => state.session);

  useEffect(() => {
    window.onunload = logout();
  }, [])

  const logout = () => {
    dispatch(clearAuth(null));
    history?.replace('/login');
  };

  // Initialize Firebase
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  } else {
    Notification.requestPermission().then(function (permission) {
      console.log('permiss', permission);
    });
  }

  const showNotification = (title, body) => {
    var options = {
      body: body,
      dir: 'ltr',
      tag: 'notification-1',
    };
    new Notification(title, options);
  };

  if (session?.token) {
    const csRef = firebase.database().ref('notification/cs');

    csRef.off('value');
    csRef.set('initialized');
    csRef.on('value', function (snapshot) {
      const data = snapshot.val();
      console.log('sessionId', session?.id);
      console.log('csagentId', data?.csagentId);
      console.log('csRef data', data);
      if (
        data !== 'initialized' &&
        data !== undefined &&
        data?.csagentId !== undefined
      ) {
        if (
          data?.senderId !== undefined &&
          data?.senderId === data?.recipientId &&
          session?.id === data?.csagentId
        ) {
          // setNotification({
          //   title: newMessageTitle,
          //   body: newMessageContent,
          // });
          const newMessageTitle = 'Hi ' + session?.name;
          const newMessageContent =
            data?.createdAt +
            '\nAda pesan untuk Anda :' +
            '\n' +
            data?.message +
            '\n' +
            data?.recipientName +
            '\nCustomer ID: ' +
            data?.recipientCustomerId;
          setTimeout(() => {
            setShow(true);
            store.dispatch(
              setFirebaseData({
                title: newMessageTitle,
                body: newMessageContent,
              })
            );
            showNotification(newMessageTitle, newMessageContent);
            store.dispatch(
              getMessages({
                limit: 10,
                offset: 0,
                filter: {},
              })
            );
            console.log('REFRESH 1');
          }, 1000);
        }
      }
    });

    const csRefInfo = firebase.database().ref('notification/info');

    csRefInfo.off('value');
    csRefInfo.set('initialized');
    csRefInfo.on('value', function (snapshot) {
      const data = snapshot.val();
      console.log('sessionId', session?.id);
      console.log('csagentId', data?.csagentId);
      console.log('csRefInfo data', data);
      if (
        data !== 'initialized' &&
        data !== undefined &&
        data?.csagentId !== undefined
      ) {
        if (session?.id === data?.csagentId) {
          const newMessageTitle = 'Hi ' + session?.name;
          const newMessageContent =
            data?.createdAt +
            '\nAda pesan untuk Anda :' +
            '\n' +
            data?.message +
            '\n' +
            data?.recipientName +
            '\nCustomer ID: ' +
            data?.recipientCustomerId;
          setTimeout(() => {
            setShow(true);
            store.dispatch(
              setFirebaseData({
                title: newMessageTitle,
                body: newMessageContent,
              })
            );
            showNotification(newMessageTitle, newMessageContent);
            store.dispatch(
              getMessages({
                limit: 10,
                offset: 0,
                filter: {},
              })
            );
            console.log('REFRESH 2');
          }, 10000);
        }
      }
    });
  }
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Fragment>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={Forgot} />
            <Route exact path="/reset-password" component={Resetp} />
            <Route exact path="/finish-reset" component={Finish} />
            <Route exact path="/unauthorized" component={Unauthorized} />
            <LayoutBaseContainer>
              {Routes.map((route, index) => (
                <PrivateRoute
                  key={index}
                  exact
                  path={route.path}
                  component={route.component}
                  access={route.access}
                />
              ))}
              {show ? (
                <CustomNotification
                  title={notification.title}
                  body={notification.body}
                />
              ) : (
                <></>
              )}
            </LayoutBaseContainer>
          </Switch>
        </Fragment>
      </Router>
    </ThemeProvider>
  );
};
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <MyApp />
          <ToastContainer
            position={toast.POSITION.TOP_RIGHT}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* <Route path="/" component={Dashboard} /> */}
        </React.StrictMode>
      </PersistGate>
    </Provider>
  );
}

export default App;
