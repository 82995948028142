import * as CONST from '@myrepublic-users/usersConstant'

export const postUsers = (payload) => ({
  type: CONST.POST_USERS,
  payload,
});

export const postUsersFailed = (payload) => ({
  type: CONST.POST_USERS_FAILED,
  payload,
});

export const postUsersSuccess = (payload) => ({
  type: CONST.POST_USERS_SUCCESS,
  payload,
});

export const postUsersReset = (payload) => ({
  type: CONST.POST_USERS_RESET,
  payload,
});

export const teknisiVendor = (payload) => ({
  type: CONST.TEKNISI_VENDOR,
  payload,
});

export const teknisiVendorFailed = (payload) => ({
  type: CONST.TEKNISI_VENDOR_FAILED,
  payload,
});

export const teknisiVendorSuccess = (payload) => ({
  type: CONST.TEKNISI_VENDOR_SUCCESS,
  payload,
});

export const teknisiVendorReset = (payload) => ({
  type: CONST.TEKNISI_VENDOR_RESET,
  payload,
});

export const putUserStatus = (payload) => ({
  type: CONST.PUT_USER_STATUS,
  payload,
})

export const putUserStatusFailed = (payload) => ({
  type: CONST.PUT_USER_STATUS_FAILED,
  payload,
})

export const putUserStatusSuccess = (payload) => ({
  type: CONST.PUT_USER_STATUS_SUCCESS,
  payload,
})

export const resetState = (payload) => ({
  type: CONST.RESET_STATE,
  payload,
});

export const createUser = (payload) => ({
  type: CONST.CREATE_USER,
  payload,
})

export const createUserFailed = (payload) => ({
  type: CONST.CREATE_USER_FAILED,
  payload,
})

export const createUserSuccess = (payload) => ({
  type: CONST.CREATE_USER_SUCCESS,
  payload,
})

export const updateUser = (payload) => ({
  type: CONST.UPDATE_USER,
  payload,
})

export const updateUserFailed = (payload) => ({
  type: CONST.UPDATE_USER_FAILED,
  payload,
})

export const updateUserSuccess = (payload) => ({
  type: CONST.UPDATE_USER_SUCCESS,
  payload,
})

export const deleteUserPermission = (payload) => ({
  type: CONST.DELETE_USER_PERMISSION,
  payload,
})

export const deleteUserPermissionFailed = (payload) => ({
  type: CONST.DELETE_USER_PERMISSION_FAILED,
  payload,
})

export const deleteUserPermissionSuccess = (payload) => ({
  type: CONST.DELETE_USER_PERMISSION_SUCCESS,
  payload,
})

export const getUserPermissionList = (payload) => ({
  type: CONST.GET_USER_PERMISSION_LIST,
  payload,
})

export const getUserPermissionListFailed = (payload) => ({
  type: CONST.GET_USER_PERMISSION_LIST_FAILED,
  payload,
})

export const getUserPermissionListSuccess = (payload) => ({
  type: CONST.GET_USER_PERMISSION_LIST_SUCCESS,
  payload,
})

export const detailUser = (payload) => ({
  type: CONST.DETAIL_USER,
  payload,
})

export const detailUserFailed = (payload) => ({
  type: CONST.DETAIL_USER_FAILED,
  payload,
})

export const detailUserSuccess = (payload) => ({
  type: CONST.DETAIL_USER_SUCCESS,
  payload,
})

export const uploadImage = (payload) => ({
  type: CONST.UPLOAD_IMAGE,
  payload,
});

export const uploadImageSuccess = (payload) => ({
  type: CONST.UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageFailed = (payload) => ({
  type: CONST.UPLOAD_IMAGE_FAILED,
  payload,
});

export const setImageUrl = (payload) => ({
  type: CONST.SET_IMAGE_URL,
  payload,
});

export const getUserRoles = (payload) => ({
  type: CONST.GET_USER_ROLES,
  payload,
});

export const getUserRolesSuccess = (payload) => ({
  type: CONST.GET_USER_ROLES_SUCCESS, 
  payload,
})

export const getUserRolesFailed = (payload) => ({
  type: CONST.GET_USER_ROLES_FAILED, 
  payload,
});

export const getTechnicianRating = (payload) => ({
  type: CONST.GET_TECHNICIAN_RATING,
  payload,
});

export const getTechnicianRatingSuccess = (payload) => ({
  type: CONST.GET_TECHNICIAN_RATING_SUCCESS, 
  payload,
})

export const getTechnicianRatingFailed = (payload) => ({
  type: CONST.GET_TECHNICIAN_RATING_FAILED, 
  payload,
});


