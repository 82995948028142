import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';

export const columns = (onClickUpdateIcon, toogleDeleteConfirmModal, handleActive) => [
  {
    dataField: '#',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'id',
    text: 'Id',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'title',
    text: 'Title',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'message',
    text: 'Message',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'chatStatus',
    text: 'Chat Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: '#',
    text: 'Sender Id',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row) => {
      return <div>{row.sender.id}</div>
    },
  },
  {
    dataField: '#',
    text: 'Sender Name',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row) => {
      return <div>{row.sender.name}</div>
    },
  },

  {
    dataField: '#',
    text: 'Agent Name',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row) => {
          return <div>{row.csagent.name}</div>
        },
  },
  // {
  //   dataField: '#',
  //   text: 'CID Customer',
  //   headerStyle: { textAlign: 'center' },
  //   style: { verticalAlign: 'middle' },
  //   formatter: (cell, row) => {
  //     return <div>{row.csagent.customerId}</div>
  //   },
  // },
  // {
  //   dataField: '#',
  //   text: 'Nama Customer',
  //   headerStyle: { textAlign: 'center' },
  //   style: { verticalAlign: 'middle' },
  //   formatter: (cell, row) => {
  //     return <div>{row.csagent.customerId}</div>
  //   },
  // },
  // {
  //   dataField: 'messageCount',
  //   text: 'Summary ',
  //   headerStyle: { textAlign: 'center' },
  //   style: { verticalAlign: 'middle' },
  // },
  // {
  //   dataField: '#',
  //   text: 'Action',
  //   headerStyle: { width: '150px', textAlign: 'center' },
  //   style: { verticalAlign: 'middle', textAlign: 'center' },
  //   formatter: (cell, row) => {
  //     return (
  //       <div className="container-button">
  //         <div className="rounded" onClick={() => onClickUpdateIcon(row)}>
  //           <CreateIcon />
  //         </div>
  //         {/* <div
  //           className="rounded"
  //           onClick={() => toogleDeleteConfirmModal(row)}>
  //           <DeleteOutlineIcon />
  //         </div> */}
  //         <div
  //           className="rounded"
  //           onClick={() => handleActive(row)}
  //           >
  //           {row.active ? <VisibilityOffIcon/> : <VisibilityIcon />}
  //         </div>
  //       </div>
  //     );
  //   },
  // },
];
