import * as CONST from '@myrepublic-users/usersConstant';
import * as STATE from '@myrepublic-users/usersInitialState';

const usersInitialState = {
  ...STATE.usersInitialState,
  action: '',
};

const usersReducer = (state = usersInitialState, action) => {
  const { payload, type } = action;
  const actions = {
    //* CUSTOMER, CUSTOMER JOURNEY, SUPER ADMIN, TEKNISI
    [CONST.POST_USERS]: () => ({
      ...state,
      postUsersFetch: true,
      postUsersParams: payload,
      action: type,
    }),
    [CONST.POST_USERS_SUCCESS]: () => ({
      ...state,
      postUsersFetch: false,
      postUsersResponse: payload,
      action: type,
    }),
    [CONST.POST_USERS_FAILED]: () => ({
      ...state,
      postUsersFetch: false,
      postUsersError: payload,
      action: type,
    }),
    [CONST.POST_USERS_RESET]: () => ({
      ...state,
      postUsersFetch: false,
      postUsersResponse: null,
      action: type,
    }),
    //* TEKNISI VENDOR
    [CONST.TEKNISI_VENDOR]: () => ({
      ...state,
      teknisiVendorFetch: true,
      teknisiVendorParams: payload,
      action: type,
    }),
    [CONST.TEKNISI_VENDOR_SUCCESS]: () => ({
      ...state,
      teknisiVendorFetch: false,
      teknisiVendorResponse: payload,
      action: type,
    }),
    [CONST.TEKNISI_VENDOR_FAILED]: () => ({
      ...state,
      teknisiVendorFetch: false,
      teknisiVendorError: payload,
      action: type,
    }),
    [CONST.TEKNISI_VENDOR_RESET]: () => ({
      ...state,
      teknisiVendorFetch: false,
      teknisiVendorResponse: null,
      action: type,
    }),
    //*UPDATE DATA USERS
    [CONST.PUT_USER_STATUS]: () => ({
      ...state,
      putUserStatusFetch: true,
      putUserStatusParams: payload,
      action: type,
    }),
    [CONST.PUT_USER_STATUS_SUCCESS]: () => ({
      ...state,
      putUserStatusFetch: true,
      putUserStatusResponse: payload,
      action: type,
    }),
    [CONST.PUT_USER_STATUS_FAILED]: () => ({
      ...state,
      putUserStatusFetch: true,
      putUserStatusError: payload,
      action: type,
    }),
    [CONST.CREATE_USER]: () => ({
      ...state,
      createUserFetch: true,
      createUserParams: payload,
      action: type,
    }),
    [CONST.CREATE_USER_SUCCESS]: () => ({
      ...state,
      createUserFetch: true,
      createUserResponse: payload,
      action: type,
    }),
    [CONST.CREATE_USER_FAILED]: () => ({
      ...state,
      createUserFetch: true,
      createUserError: payload,
      action: type,
    }),
    [CONST.UPDATE_USER]: () => ({
      ...state,
      updateUserFetch: true,
      updateUserParams: payload,
      action: type,
    }),
    [CONST.UPDATE_USER_SUCCESS]: () => ({
      ...state,
      updateUserFetch: true,
      updateUserResponse: payload,
      action: type,
    }),
    [CONST.UPDATE_USER_FAILED]: () => ({
      ...state,
      updateUserFetch: true,
      updateUserError: payload,
      action: type,
    }),
    [CONST.DELETE_USER_PERMISSION]: () => ({
      ...state,
      deleteUserPermissionFetch: true,
      deleteUserPermissionParams: payload,
      action: type,
    }),
    [CONST.DELETE_USER_PERMISSION_SUCCESS]: () => ({
      ...state,
      deleteUserPermissionFetch: true,
      deleteUserPermissionResponse: payload,
      action: type,
    }),
    [CONST.DELETE_USER_PERMISSION_FAILED]: () => ({
      ...state,
      deleteUserPermissionFetch: true,
      deleteUserPermissionError: payload,
      action: type,
    }),
    [CONST.GET_USER_PERMISSION_LIST]: () => ({
      ...state,
      getUserPermissionListFetch: true,
      getUserPermissionListParams: payload,
      action: type,
    }),
    [CONST.GET_USER_PERMISSION_LIST_SUCCESS]: () => ({
      ...state,
      getUserPermissionListFetch: true,
      getUserPermissionListResponse: payload,
      action: type,
    }),
    [CONST.GET_USER_PERMISSION_LIST_FAILED]: () => ({
      ...state,
      getUserPermissionListFetch: true,
      getUserPermissionListError: payload,
      action: type,
    }),

    [CONST.RESET_STATE]: () => ({
      ...state,
      createUserResponse: {},
      putUserStatusResponse: {},
      updateUserResponse: {},
      action: type,
    }),
    [CONST.DETAIL_USER]: () => ({
      ...state,
      detailUserFetch: true,
      detailUserParams: payload,
      action: type,
    }),
    [CONST.DETAIL_USER_SUCCESS]: () => ({
      ...state,
      detailUserFetch: true,
      detailUserResponse: payload,
      action: type,
    }),
    [CONST.DETAIL_USER_FAILED]: () => ({
      ...state,
      detailUserFetch: true,
      detailUserError: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageParams: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_SUCCESS]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageResponse: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_FAILED]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageError: payload,
      action: type,
    }),
    [CONST.SET_IMAGE_URL]: () => ({
      ...state,
      uploadImageFetch: false,
      icon: payload,
    }),
    [CONST.GET_USER_ROLES]: () => ({
      ...state,
      getUserRolesFetch: false,
      getUserRolesParams: payload,
      action: type,
    }),
    [CONST.GET_USER_ROLES_SUCCESS]: () => ({
      ...state,
      getUserRolesFetch: false,
      getUserRolesResponse: payload,
      action: type,
    }),
    [CONST.GET_USER_ROLES_FAILED]: () => ({
      ...state,
      getUserRolesFetch: false,
      getUserRolesError: payload,
      action: type,
    }),
    [CONST.GET_TECHNICIAN_RATING]: () => ({
      ...state,
      getTechnicianRatingFetch: false,
      getTechnicianRatingParams: payload,
      action: type,
    }),
    [CONST.GET_TECHNICIAN_RATING_SUCCESS]: () => ({
      ...state,
      getTechnicianRatingFetch: false,
      getTechnicianRatingResponse: payload,
      action: type,
    }),
    [CONST.GET_TECHNICIAN_RATING_FAILED]: () => ({
      ...state,
      getTechnicianRatingFetch: false,
      getTechnicianRatingError: payload,
      action: type,
    }),
    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default usersReducer;
