import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { 
  postCSAgentHistory as postCSAgentHistoryProps,
  postCSAgentChatSummary as postCSAgentChatSummaryProps
} from '@myrepublic-livechat/liveChatAction';
import { columns } from './column';
import { useHistory } from 'react-router';

function OpenConfirm(props) {
  const { 
    handleClose, 
    handleClickOpen,
    open 
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function History(props) {
  const {
    postCSAgentHistory,
    postCSAgentHistoryResponse,
    pagination,
    postCSAgentChatSummary,
    postCSAgentChatSummaryResponse,
    match: { params },
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [totalData, setTotalData] = useState();
  const [roleId, setRoleId] = useState();
  const history = useHistory();

  const [deleteParam, setDeleteParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });

  const getDataTable = () => {
    if(params == undefined) postCSAgentHistory({...pagination, csAgentId: postCSAgentChatSummaryResponse?.csagentId});
    else postCSAgentHistory({...pagination, csAgentId: Number(params.id)});
  };

  useEffect(() => {
    getDataTable();
  }, [postCSAgentChatSummaryResponse?.csagentId, pagination])

  useEffect(() => {
    postCSAgentChatSummary();
  }, []);

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleActive = (row) => {
    // putUserStatus({id: row.id, payload: {active: !row.active}})
  }

  const handleDelete = (row) => {
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    // resetState({});
    console.log('handleEdit');
    history.push('/update-customer/' + row?.id);
  };

  const modalShowData = () => {
    // setShowCreateModal(true);
    // history.push('/create-product');
  };

  useEffect(() => {
    console.log('postCSAgentHistoryResponse', postCSAgentHistoryResponse);
    console.log("pagination is", pagination)
  }, [postCSAgentHistoryResponse])


  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={postCSAgentHistoryResponse?.totalElements}
        totalPage={pagination.limit}
        wrapTitle={'History'}
        data={postCSAgentHistoryResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <h6><b>History Table</b></h6>
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postCSAgentHistoryResponse: state.livechat.postCSAgentHistoryResponse,
  postCSAgentChatSummaryResponse: state.livechat.postCSAgentChatSummaryResponse
});

const mapDispatchToProps = {
  postCSAgentHistory: (payload) => postCSAgentHistoryProps(payload),
  postCSAgentChatSummary: (payload) => postCSAgentChatSummaryProps(payload)
};
export default connect(mapStateToProps, mapDispatchToProps)(History);

