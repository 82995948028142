import React, { Component, Fragment } from 'react';
import { Pagination } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { PaginationHandler as PaginationHandlerProps } from '@myrepublic-bootstrap/bootstrapAction';
import './styles.scss';
import { isThisTypeNode } from 'typescript';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

class HandlerPaginationProps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      selectedOption: 10,
      valueSelected: 10,
      totalRecords: this.props.totalRecords,
    };
    this.fetchPageNumbers = this.fetchPageNumbers.bind(this);
    const { totalRecords = 1, pageLimit = 10, showPerPage = 3 } = props;

    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
    this.totalRecords =
      typeof totalRecords === 'number' ? this.state.totalRecords : 1;

    this.pageNeighbours =
      typeof showPerPage === 'number'
        ? Math.max(0, Math.min(showPerPage, 2))
        : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
  }

  async componentDidMount() {
    await this.setState({
      totalRecords: this.props.totalRecords,
    });
    this.gotoPage(1);
    this.fetchPageNumbers();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.totalRecords !== this.props.totalRecords) {
      await this.setState({ totalRecords: this.props.totalRecords });
    }
  }

  gotoPage = (page) => {
    const { onPageChanged = (f) => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords,
    };

    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClick = (page, evt) => {
    evt.preventDefault();
    this.gotoPage(page);
    this.props.PaginationHandler({
      ...this.props.pagination,
      offset: Number(page - 1),
    });
  };

  handleMoveLeft = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;
    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.PaginationHandler({
      ...this.props.pagination,
      limit: Number(selectedOption.value),
    });
  };

  render() {
    if (!this.totalRecords) return null;

    if (this.totalPages === null) return null;

    const { currentPage, selectedOption } = this.state;
    const pages = this.fetchPageNumbers();
    const rangeData = [
      { value: 10, label: 10 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
      { value: 200, label: 200 },
    ];
    return (
      <Row className="pagination-container">
        <Col className="d-flex flex-row align-items-center mb-2">
          <Select
            value={selectedOption}
            options={rangeData}
            onChange={(e) => {
              this.handleChange(e);
              // this.props.handleChangeParams({
              // ...this.props.pagination,
              // limit: e.value,
              // });
            }}
            className="select mx-2"
            placeholder={this.props.pagination.limit}
          />
          <label>
            Menampilkan{' '}
            {selectedOption?.value ? selectedOption.value : selectedOption} Data
            dari Total{' '}
            {this.totalRecords < selectedOption?.value
              ? `-${this.totalRecords}`
              : this.totalRecords}
          </label>
        </Col>
        <Col>
          <ul className="pagination d-flex mt-1 justify-content-end">
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <li key={index} className="page-item d-flex flex-row">
                    <a
                      className="page-link"
                      style={{ fontWeight: 'bold' }}
                      href="#"
                      aria-label="Previous"
                      onClick={this.handleMoveLeft}>
                      <span aria-hidden="true"> {'<<'} </span>
                    </a>
                    <a
                      className="page-link"
                      href="#"
                      style={{ fontWeight: 'bold' }}
                      aria-label="Previous"
                      onClick={this.handleMoveLeft}>
                      <span aria-hidden="true"> {'<'} </span>
                    </a>
                  </li>
                );

              if (page === RIGHT_PAGE)
                return (
                  <li key={index} className="page-item d-flex flex-row">
                    <a
                      className="page-link"
                      href="#"
                      style={{ fontWeight: 'bold' }}
                      aria-label="Next"
                      onClick={this.handleMoveRight}>
                      <span aria-hidden="true"> {'>'} </span>
                    </a>
                    <a
                      className="page-link"
                      href="#"
                      style={{ fontWeight: 'bold' }}
                      aria-label="Next"
                      onClick={this.handleMoveRight}>
                      <span aria-hidden="true"> {'>>'} </span>
                    </a>
                  </li>
                );

              return (
                <li
                  key={index}
                  className={`page-item${
                    currentPage === page ? ' active' : ''
                  }`}>
                  <a
                    className="page-link"
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    onClick={(e) => {
                      this.handleClick(page, e);
                      // this.props.handleChangeParams({
                      //   ...this.props.pagination,
                      //   offset: Number(page),
                      // });
                    }}>
                    {page}
                  </a>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
});

const mapDispatchToProps = {
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandlerPaginationProps);
