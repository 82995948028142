function truncate(str, n) {
  let newString = str.replace(/(<([^>]+)>)/gi, '');
  return newString.length > n ? newString.substr(0, n - 1) + '...' : newString;
}

const isAllowed = (session, access = '', param) => {
  const userPermissions = [];

  console.log("session", session, access)
  console.log("param is", param)

  if (!session) return false;
  let result = false;
  if (session?.role?.name === 'Superadmin') result = true;
  else if (session?.userPermissions) {
    session?.userPermissions?.forEach((value) => {
      const accessNode = value.permission.path + '_' + value.permission.access;
      console.log('access Node is', accessNode)
      userPermissions.push(accessNode);
    });
    access.split(',').forEach((e) => {
      console.log("access is", access)
      if (userPermissions.includes(e.trim())) result = true;
      else if(access == '') result = true;
    });
  }
  console.log("result is", result)
  return result;
};

const pushToArray = (arr, obj) => {
  const index = arr.findIndex(
    (e) => parseInt(e.sender.id) === parseInt(obj.senderId)
  );

  if (index === -1) {
    arr.push(obj);
  } else {
    arr[index] = obj;
  }
  return arr;
};

export { truncate, isAllowed, pushToArray };
