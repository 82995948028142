import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { columns } from './column';
import { useHistory } from 'react-router';
import { postLiveChatSettings as postLiveChatSettingsProps} from '@myrepublic-livechat/liveChatAction'

function SettingLiveChat(props) {
  const {
    postLiveChatSettings,
    postLiveChatSettingsResponse
  } = props;

  const history = useHistory();

  const handleActive = (row) => {
    // putUserStatus({id: row.id, payload: {active: !row.active}})
  }

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };
  const handleEdit = (row) => {

    // resetState({});
    // console.log('handleEdit');
    console.log("click ", row?.id)
    history.push('/update-livechat-setting/' + row?.id);
  };


  useEffect(() => {
    console.log("helo its use")
    console.log(postLiveChatSettings({
      limit: 10,
      offset: 0,
      filter: {}
      }));
  }, [])

  useEffect(() => {
    console.log("response in here is", postLiveChatSettingsResponse)
  }, [postLiveChatSettingsResponse])

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={1}
        totalPage={1}
        wrapTitle={'Live Chat Setting'}
        data={postLiveChatSettingsResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        {/* <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        /> */}
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  postLiveChatSettingsResponse: state.livechat.postLiveChatSettingsResponse
});

const mapDispatchToProps = {
  postLiveChatSettings: (payload) => postLiveChatSettingsProps(payload)
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingLiveChat);

