import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';


export const columns = (onClickUpdateIcon, toogleDeleteConfirmModal, handleActive) => [
  {
    dataField: '#',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'companyName',
    text: 'Nama',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'pic',
    text: 'PIC',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'handphone',
    text: 'HP',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'telephone',
    text: 'Telepon',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'address',
    text: 'Alamat',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: '#',
    text: 'Area',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        row.areaId.length > 0 ?
        row.areaId.map((i) => (
          <div>{i.name}</div>
        ))
        : 
        <div>No area</div>
      )
    }
  },
  {
    dataField: '#',
    text: 'Action',
    headerStyle: { width: '150px', textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <div className="container-button">
          <div
            className="rounded"
            onClick={() => onClickUpdateIcon(row)}
            >
            <CreateIcon/>
          </div>
          <div
            className="rounded"
            onClick={() => handleActive(row)}
            >
            {row.statusActive ? <VisibilityOffIcon/> : <VisibilityIcon />}
          </div>
        </div>
      );
    },
  },
];
