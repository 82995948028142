import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { 
  teknisiVendor as teknisiVendorProps, 
  putUserStatus as putUserStatusProps,
  getUserRoles as getUserRolesProps,
  teknisiVendorReset as teknisiVendorResetProps,
} from '@myrepublic-users/usersAction';
import Rating from './rating';

import { columns } from './column';
import { useHistory } from 'react-router';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TeknisiVendor(props) {
  const {
    pagination,
    teknisiVendor,
    teknisiVendorReset,
    teknisiVendorResponse,
    putUserStatus,
    putUserStatusResponse,
    getUserRoles, 
    getUserRolesResponse,
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [roleId, setRoleId] = useState();
//   const [key, setKey] = useState(0);
  const history = useHistory();
//   let closeToast = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setOpenErrorInfo(false);
//   };


  const [deleteParam, setDeleteParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });

  const getDataTable = () => {
    teknisiVendor({data: {...pagination, filter: {roleName: 'Technician'}}});
  };

  useEffect(() => {
    getDataTable();
  }, [roleId])

  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex((i) => i.name === "Technician");
    setRoleId(getUserRolesResponse?.data?.content[index]?.id);
  }, [getUserRolesResponse])

  useEffect(() => {
    teknisiVendorReset();
    getUserRoles();
  }, []);

  useEffect(() => {
    getDataTable();
  }, [putUserStatusResponse, pagination]);

  // const handleOpenErrorInfo = () => {
  //   setOpenErrorInfo(true);
  // };
  // const handleClose = () => {
  //   setShowCreateModal(false);
  // };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    // deleteProduct({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };
  const handleDelete = (row) => {
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    console.log(row)
    history.push(`/detail-teknisi/${row.id}`)
  };

  const handleApprove = (row) => {
    putUserStatus({id: row.id, payload: {active: !row.active}})
  }

  const handleReview = (row) => {
    console.log('row is', row)
  }

  const modalShowData = () => {
    // setShowCreateModal(true);
    // history.push('/create-product');
  };


  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={teknisiVendorResponse?.data?.totalElements || 0}
        totalPage={pagination.limit}
        data={teknisiVendorResponse?.data?.content}
        wrapTitle={'Teknisi'}
        columns={columns(handleEdit, handleDelete, handleApprove, handleReview)}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  teknisiVendorResponse: state.user.teknisiVendorResponse,
  putUserStatusResponse: state.user.putUserStatusResponse,
  getUserRolesResponse: state.user.getUserRolesResponse,
});

const mapDispatchToProps = {
  teknisiVendor: (payload) => teknisiVendorProps(payload),
  teknisiVendorReset: (payload) => teknisiVendorResetProps(payload),
  putUserStatus: (payload) => putUserStatusProps(payload),
  getUserRoles: (payload) => getUserRolesProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(TeknisiVendor);
