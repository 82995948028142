import HandlerPaginationProps from '@myrepublic-components/container/CustomPagination';
import WrapperContainer from '@myrepublic-components/container/wrapper';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
function WrapContainerBased(props) {
  const {
    topContent,
    columns,
    data,
    children,
    wrapTitle,
    withButton,
    withTable,
    withPagination = false,
    totalRecord,
    totalPage,
    showPerPage,
    handleChangePage,
  } = props;

  return (
    <WrapperContainer
      wrapTitle={wrapTitle}
      topContent={topContent}
      withButton={withButton}
      withPagination={withPagination}
      totalRecord={totalRecord}
      totalPage={totalPage}
      showPerPage={showPerPage}
      handleChangePage={handleChangePage}>
      {children}
      {withTable && totalRecord > 0 && (
        <BootstrapTable
          keyField="id"
          data={data || []}
          columns={columns}
          striped
          hover
          condensed
        />
      )}
      {/* <HandlerPaginationProps /> */}
    </WrapperContainer>
  );
}

export default WrapContainerBased;
