import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { 
  putVendor as putVendorProps, 
  getVendorDetail as getVendorDetailProps
} from '@myrepublic-wokumo/wokumoAction';
import { DropzoneArea } from 'material-ui-dropzone';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function UpdateVendor(props) {
  const {
    putVendor,
    putVendorResponse,
    getVendorDetail,
    getVendorDetailResponse,
    postVendorError,
    match: { params },
  } = props;
  const [data, setData] = useState({});
  const history = useHistory();

  const handleOnSave = () => {
      console.log('SEND DATA', data);

      putVendor({
        id: params.id, 
        body: data
      })
  };

  useEffect(() => {
    console.log("getVendorDetailResponse", getVendorDetailResponse)
    setData(getVendorDetailResponse)
  }, [getVendorDetailResponse])

  useEffect(() => {
    console.log("params is", params)
    getVendorDetail({id: params.id})
  }, [])

  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>
            <h5><b>Create Vendor</b></h5>
        </CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>Company Name</h6>
                <Form.Control
                  value={data?.companyName}
                  type="text"
                  placeholder="Company Name"
                  onChange={(e) => setData({ ...data, companyName: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>Handphone</h6>
                <Form.Control
                  value={data?.handphone}
                  type="text"
                  placeholder="Handphone"
                  onChange={(e) => setData({ ...data, handphone: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>PIC</h6>
                <Form.Control
                  value={data?.pic}
                  type="text"
                  placeholder="PIC"
                  onChange={(e) => setData({ ...data, pic: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>Telephone</h6>
                <Form.Control
                  value={data?.telephone}
                  type="text"
                  placeholder="Telephone"
                  onChange={(e) => setData({ ...data, telephone: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>Address</h6>
                <Form.Control
                  value={data?.address}
                  type="text"
                  placeholder="Address"
                  onChange={(e) => setData({ ...data, address: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>Province</h6>
                <Form.Control
                  value={data?.provinceId?.id}
                  type="number"
                  placeholder="Province"
                  onChange={(e) => setData({ ...data, province: Number(e.target.value) })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>Regency</h6>
                <Form.Control
                  value={data?.regencyId?.id}
                  type="number"
                  placeholder="Regency"
                  onChange={(e) => setData({ ...data, regency: Number(e.target.value) })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>District</h6>
                <Form.Control
                  value={data?.districtId?.id}
                  type="number"
                  placeholder="District"
                  onChange={(e) => setData({ ...data, district: Number(e.target.value) })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>Village</h6>
                <Form.Control
                  value={data?.villageId?.id}
                  type="number"
                  placeholder="Village"
                  onChange={(e) => setData({ ...data, village: Number(e.target.value) })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>Area</h6>
                <Form.Control
                  value={data?.area}
                  type="number"
                  placeholder="Area"
                  onChange={(e) => setData({ ...data, area: [Number(e.target.value)] })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="bg-primary"
          onClick={handleOnSave}
          disabled={false}>
          Simpan
        </Button>
      </CardFooter>
    </Card>
  );
}
const mapStateToProps = (state) => ({
    putVendorResponse: state.wokumo.putVendorResponse,
    getVendorDetailResponse: state.wokumo.getVendorDetailResponse

});
const mapDispatchToProps = {
    putVendor: (payload) => putVendorProps(payload),
    getVendorDetail: (payload) => getVendorDetailProps(payload)
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVendor);
