import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Paper, makeStyles, Grid } from '@material-ui/core';
import DatatableServer from '../../../components/organisms/datatable';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { DropzoneArea } from 'material-ui-dropzone';
import Alert from '@material-ui/lab/Alert';
import data from './data.json';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  getDataTrouble as getDataTroubleProps,
  createDataTrouble as createDataTroubleProps,
  deleteDataTrouble as deleteDataTroubleProps,
  answerDataTrouble as answerDataTroubleProps,
  putDataTrouble as putDataTroubleProps,
  deleteAnswerDataTrouble as deleteAnswerDataTroubleProps,
  getAnswerTrouble as getAnswerTroubleProps,
  resetAnswerData as resetAnswerDataProps,
  putAnswerTrouble as putAnswerTroubleProps,
} from '@myrepublic-troubleshooting/troubleShootingAction';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';
import { Button, Form, Modal } from 'react-bootstrap';
import { columns } from './column';
import { answerColumn } from './answerColumn';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  container: {
    maxHeight: 640,
  },
}));

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function OpenConfirmAnswer(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function MyVerticallyCenteredModal(props) {
  const { rowData } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          TroubleShooting Answer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{rowData?.title}</h4>
        {rowData?.troubleshootingAnswers?.map((item, index) => {
          return (
            <div>
              <div className="d-flex align-items-center flex-row">
                <span>
                  <label>
                    {index + 1}. {item.title}
                  </label>{' '}
                  <CreateIcon fontSize="small" className="hover-in" />{' '}
                  <DeleteOutlineIcon fontSize="small" className="hover-in" />
                </span>
              </div>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function CreateModal(props) {
  const { show, handleClose, onChangeText, onChangeDescription, handleSave } =
    props;
  return (
    <Modal show={show} onHide={handleClose} className="modal-data">
      <Modal.Header closeButton>
        <Modal.Title>TroubbleShooting Create</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Create Title"
              onChange={onChangeText}
            />
            <Form.Text className="text-muted">
              Create Title TroubleShooting
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Description</Form.Label>
            {/*<Form.Control
              as="textarea"
              rows={3}
              onChange={onChangeDescription}
            />*/}
            <CKEditor
              editor={ClassicEditor}
              data=""
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log('Editor event data');
                // console.log({ event, editor, data });
                onChangeDescription(data);
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function UpdateModal(props) {
  const {
    show,
    handleClose,
    onChangeText,
    onChangeDescription,
    handleSave,
    dataTrouble,
  } = props;
  return (
    <Modal show={show} onHide={handleClose} className="modal-data">
      <Modal.Header closeButton>
        <Modal.Title>TroubbleShooting Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Create Title"
              value={dataTrouble?.title}
              onChange={onChangeText}
            />
            <Form.Text className="text-muted">
              Update Title TroubleShooting
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Description</Form.Label>
            {/*<Form.Control
              as="textarea"
              rows={3}
              value={dataTrouble?.description}
              onChange={onChangeDescription}
            />*/}
            <CKEditor
              editor={ClassicEditor}
              data={dataTrouble?.description}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log('Editor event data');
                // console.log({ event, editor, data });
                onChangeDescription(data);
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function AnswerTroubleModal(props) {
  const {
    show,
    handleClose,
    onChangeText,
    onChangeDescription,
    handleSave,
    dataTrouble,
    onSelectParamId,
  } = props;
  return (
    <Modal show={show} onHide={handleClose} className="modal-data">
      <Modal.Header closeButton>
        <Modal.Title>TroubbleShooting Answer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label>Select to add answer trouble</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={onSelectParamId}>
            <option>Select TroubleShooting Option</option>
            {dataTrouble?.map((item, index) => (
              <option value={item?.id}>{item?.title}</option>
            ))}
          </Form.Select>
          <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Create Title"
              onChange={onChangeText}
            />
            <Form.Text className="text-muted">
              Create Title TroubleShooting Answer
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Description</Form.Label>
            {/*<Form.Control
              as="textarea"
              rows={3}
              onChange={onChangeDescription}
            />*/}
            <CKEditor
              editor={ClassicEditor}
              data=""
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log('Editor event data');
                // console.log({ event, editor, data });
                onChangeDescription(data);
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function TroubleShooting(props) {
  const {
    getDataTrouble,
    getDataTroubleResponse,
    createDataTrouble,
    deleteDataTrouble,
    answerDataTrouble,
    deleteDataTroubleResponse,
    createDataTroubleResponse,
    answerDataTroubleResponse,
    putDataTroubleResponse,
    putDataTrouble,
    getAnswerTrouble,
    getAnswerTroubleResponse,
    deleteAnswerDataTroubleResponse,
    deleteAnswerDataTrouble,
    resetAnswerData,
    putAnswerTrouble,
    putAnswerTroubleResponse,
    pagination,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [onGetData, setOnGetData] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDeleteAnswer, setOpenModalDeleteAnswer] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [key, setKey] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [getId, setGetId] = useState(0);
  const [getColumn, setGetColumn] = useState(1);

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const [deleteParam, setDeleteParam] = useState({
    title: '',
    description: '',
  });

  const [deleteParamAnswer, setDeleteParamAnswer] = useState({
    title: '',
    description: '',
  });
  const [editParam, setEditParam] = useState({
    title: '',
    description: '',
  });
  const [data, setData] = useState({
    title: '',
    description: '',
  });
  const [showModalAnswer, setShowModalAnswer] = useState(false);
  const [dataAnswer, setDataAnswer] = useState({
    troubleshootingId: 0,
    title: '',
    description: '',
  });
  const getDataTable = () => {
    const initialLimit = {
      limit: 10,
      offset: 0,
    };
    getDataTrouble({ ...pagination });
  };
  const getAnswerTable = async (id) => {
    const filter = {
      limit: 10,
      offset: 0,
    };
    await getAnswerTrouble({ id: id, ...filter });
  };
  useEffect(() => {
    getDataTable();
  }, [
    deleteDataTroubleResponse,
    createDataTroubleResponse,
    answerDataTroubleResponse,
    putDataTroubleResponse,
    pagination,
  ]);

  useEffect(() => {
    getAnswerTable(getId);
  }, [deleteAnswerDataTroubleResponse, putAnswerTroubleResponse]);

  const toogleModal = (row, data) => {
    if (data?.title === 'view') {
      setGetId(row?.id);
      getAnswerTable(row?.id);
      setGetColumn(2);
    } else if (data?.title === 'delete') {
      if (getColumn === 1) {
        handleDelete(row);
      } else {
        handleDeleteAnswer(row);
      }
    } else if (data?.title === 'update') {
      handleEdit(row);
    }
  };
  const handleOpenErrorInfo = () => {
    setOpenErrorInfo(true);
  };
  const handleClose = () => {
    setShowCreateModal(false);
  };
  const handleDelete = (row) => {
    setDeleteParam({
      data: row,
    });
    setOpenModalDelete(true);
  };
  const handleDeleteAnswer = (row) => {
    setDeleteParamAnswer({
      data: row,
    });
    setOpenModalDeleteAnswer(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleCloseModalDeleteAnswer = () => {
    setOpenModalDeleteAnswer(false);
  };
  const handleOpenModalDelete = () => {
    deleteDataTrouble({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };
  const handleOpenModalDeleteAnswer = () => {
    deleteAnswerDataTrouble({ id: deleteParamAnswer?.data?.id });
    setOpenModalDeleteAnswer(false);
  };

  const modalShowData = () => {
    setShowCreateModal(true);
  };

  const handleOnSave = () => {
    createDataTrouble({ ...data });
    setData({ title: '', description: '' });
    setShowCreateModal(false);
    getDataTable();
  };

  const saveAnswerData = () => {
    answerDataTrouble({ ...dataAnswer });
    setShowModalAnswer(!showModalAnswer);
  };

  const handleEdit = (row) => {
    if (getColumn === 1) {
      setEditParam({
        title: row?.title,
        description: row?.description,
        data: row,
      });
    } else if (getColumn === 2) {
      setDataAnswer({
        id: row?.id,
        troubleshootingId: row?.troubleshootingId,
        title: row?.title,
        description: row?.description,
      });
    }
    setOpenModalEdit(true);
  };
  const onUpdateData = () => {
    if (getColumn === 1) {
      const editData = {
        title: editParam?.title,
        description: editParam?.description,
      };
      putDataTrouble({ id: editParam?.data?.id, ...editData });
    } else if (getColumn === 2) {
      putAnswerTrouble({ id: dataAnswer?.id, ...dataAnswer });
    }
    setOpenModalEdit(false);
  };
  const buttonComponent = () => {
    return (
      <>
        {getColumn !== 1 ? (
          <div className="d-flex justify-content-sp mt-2 px-3 gap-3">
            <Button variant="primary" size="sm" onClick={() => setGetColumn(1)}>
              {' '}
              Back To Trouble Data
            </Button>
          </div>
        ) : (
          <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
            <Button variant="outline-primary" size="sm" onClick={modalShowData}>
              {' '}
              Add Trouble
            </Button>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setShowModalAnswer(!showModalAnswer)}>
              {' '}
              Add Answer
            </Button>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={getDataTroubleResponse?.totalElements}
        totalPage={pagination.limit}
        wrapTitle={
          getColumn === 1 ? 'Trouble Shooting Data' : 'Trouble Shooting Answer'
        }
        data={
          getColumn === 1
            ? getDataTroubleResponse?.content
            : getAnswerTroubleResponse?.content
        }
        columns={
          getColumn === 1 ? columns(toogleModal) : answerColumn(toogleModal)
        }
        withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
        <OpenConfirmAnswer
          handleClose={handleCloseModalDeleteAnswer}
          handleClickOpen={handleOpenModalDeleteAnswer}
          open={openModalDeleteAnswer}
        />
        <MyVerticallyCenteredModal
          show={showModal}
          rowData={rowData}
          onHide={() => setShowModal(false)}
        />
        <CreateModal
          show={showCreateModal}
          handleClose={handleClose}
          handleSave={handleOnSave}
          onChangeText={(e) => setData({ ...data, title: e.target.value })}
          onChangeDescription={(e) => {
            setData({ ...data, description: e });
            closeToast = { closeToast };
          }}
        />
        <AnswerTroubleModal
          show={showModalAnswer}
          handleClose={() => setShowModalAnswer(!showModalAnswer)}
          handleSave={saveAnswerData}
          dataTrouble={getDataTroubleResponse?.content}
          onSelectParamId={(e) =>
            setDataAnswer({
              ...dataAnswer,
              troubleshootingId: Number(e.target.value),
            })
          }
          onChangeText={(e) =>
            setDataAnswer({ ...dataAnswer, title: e.target.value })
          }
          onChangeDescription={(e) =>
            setDataAnswer({ ...dataAnswer, description: e })
          }
        />
        <UpdateModal
          show={openModalEdit}
          handleClose={() => setOpenModalEdit(!openModalEdit)}
          handleSave={onUpdateData}
          dataTrouble={getColumn === 1 ? editParam : dataAnswer}
          onChangeText={(e) => {
            getColumn === 1
              ? setEditParam({ ...editParam, title: e.target.value })
              : setDataAnswer({
                  ...dataAnswer,
                  troubleshootingId: getId,
                  title: e.target.value,
                });
          }}
          onChangeDescription={(e) => {
            getColumn === 1
              ? setEditParam({ ...editParam, description: e })
              : setDataAnswer({ ...dataAnswer, description: e });
          }}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  getDataTroubleResponse: state.trouble.getDataTroubleResponse,
  deleteDataTroubleResponse: state.trouble.deleteDataTroubleResponse,
  createDataTroubleResponse: state.trouble.createDataTroubleResponse,
  answerDataTroubleResponse: state.trouble.answerDataTroubleResponse,
  putDataTroubleResponse: state.trouble.putDataTroubleResponse,
  getAnswerTroubleResponse: state.trouble.getAnswerTroubleResponse,
  deleteAnswerDataTroubleResponse:
    state.trouble.deleteAnswerDataTroubleResponse,
  putAnswerTroubleResponse: state.trouble.putAnswerTroubleResponse,
  pagination: state.paging.pagination,
});

const mapDispatchToProps = {
  getDataTrouble: (payload) => getDataTroubleProps(payload),
  createDataTrouble: (payload) => createDataTroubleProps(payload),
  deleteDataTrouble: (payload) => deleteDataTroubleProps(payload),
  answerDataTrouble: (payload) => answerDataTroubleProps(payload),
  putDataTrouble: (payload) => putDataTroubleProps(payload),
  getAnswerTrouble: (payload) => getAnswerTroubleProps(payload),
  deleteAnswerDataTrouble: (payload) => deleteAnswerDataTroubleProps(payload),
  resetAnswerData: () => resetAnswerDataProps(),
  putAnswerTrouble: (payload) => putAnswerTroubleProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(TroubleShooting);
