import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import {
  getTechnicianRating as getTechnicianRatingProps
} from '@myrepublic-users/usersAction';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function Rating(props) {
  const {
    getTechnicianRating,
    getTechnicianRatingResponse, 
    technicianId,
    handleOnSave,
  } = props;

  const [data, setData] = useState();
  
  useEffect(() => {
    console.log("technicianId", technicianId)
  }, [])

  useEffect(() => {
    console.log("data is", data)
  }, [data])

  useEffect(() => {
    console.log('getTechnicianRatingResponse', getTechnicianRatingResponse)
  }, [getTechnicianRatingResponse])

  const Summary = (data) => {
      console.log("data on summary", data)
      return (
          <div style={{marginTop:20}}>
              <h6><b>Summary</b></h6>
              <div>Rating: {data?.data?.summaryString}</div>
              <div>Average Rating: {data?.data?.average}</div>
              <div>Work Order Success: {data?.data?.woSuccess}</div>
          </div>
      )
  }

  function handleSave(params) {
    getTechnicianRating({
      limit: 10,
      offset: 0,
      filter: { ...data, technicianId: Number(technicianId) },
    });
    handleOnSave();
  }

  return (
    <Card className="mx-4 card-box" style={{marginTop: 10}}>
      <CardHeader>
        <CardTitle>
          <h5>Rating Teknisi</h5>
        </CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Date Start</Form.Label>
                <Form.Control
                  value={data?.customerRateTimeStart}
                  type="date"
                  placeholder="Date Start"
                  onChange={(e) => setData({...data, customerRateTimeStart: e.target.value})}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Date End</Form.Label>
                <Form.Control
                  value={data?.customerRateTimeEnd}
                  type="date"
                  placeholder="Date End"
                  onChange={(e) => setData({...data, customerRateTimeEnd: e.target.value})}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
            <Button
                className="bg-primary"
                onClick={handleSave}
                // disabled={updateUserResponse.code === 200 ? true : false}
            >
                Simpan
            </Button>
            {getTechnicianRatingResponse?.data?.summary != undefined && <Summary data={getTechnicianRatingResponse?.data?.summary}/> }
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
const mapStateToProps = (state) => ({
    getTechnicianRatingResponse: state.user.getTechnicianRatingResponse,
});
const mapDispatchToProps = {
    getTechnicianRating: (payload) => getTechnicianRatingProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
