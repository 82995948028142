import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { columns } from './column';
import { useHistory } from 'react-router';
import { 
  getVendor as getVendorProps,
  putVendorStatus as putVendorStatusProps,
  resetState as resetStateProps 
} from '@myrepublic-wokumo/wokumoAction'

// function OpenConfirm(props) {
//   const { 
//     handleClose, 
//     handleClickOpen,
//     open 
//   } = props;

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description">
//         <DialogTitle id="alert-dialog-title">
//           {'Konfirmasi hapus data'}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Apakah anda yakin akan menghapus data?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Tidak
//           </Button>
//           <Button onClick={handleClickOpen} color="primary" autoFocus>
//             Ya
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

function Vendor(props) {
  const {
    getVendor,
    getVendorResponse,
    putVendorStatus,
    putVendorStatusResponse,
    pagination,
    resetState
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);

  const history = useHistory();

  const getDataTable = () => {
    getVendor({
          ...pagination
    });
  };

  useEffect(() => {
    getDataTable();
  }, [putVendorStatusResponse, pagination])

  const handleActive = (row) => {
    putVendorStatus({id: row.id, body: {statusActive: !row.statusActive}});
  }

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };

  const handleAdd = () => {
    resetState({});
    history.push('/create-vendor')
  };
  const handleEdit = (row) => {
    console.log('row is', row)
    history.push(`/update-vendor/${row.id}`);
  };

  const modalShowData = () => {
    history.push('/create-vendor');
  };

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={() => handleAdd()}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={getVendorResponse?.totalElements || 0}
        totalPage={pagination.limit}
        wrapTitle={'Vendor'}
        data={getVendorResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        withButton={buttonComponent()}
        handleAddData={modalShowData}
      >
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  getVendorResponse: state.wokumo.getVendorResponse,
  putVendorStatusResponse: state.wokumo.putVendorStatusResponse,
});

const mapDispatchToProps = {
  resetState: () => resetStateProps(),
  getVendor: (payload) => getVendorProps(payload),
  putVendorStatus: (payload) => putVendorStatusProps(payload),

};
export default connect(mapStateToProps, mapDispatchToProps)(Vendor);

