import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import StarIcon from '@material-ui/icons/Star';

export const columns = (
  onClickUpdateIcon,
  toogleDeleteConfirmModal,
  handleApprove,
  handleReview
) => [
  {
    dataField: '#',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'name',
    text: 'Nama',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'vendorName',
    text: 'Vendor',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'username',
    text: 'Username',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'handphone',
    text: 'HP',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'email',
    text: 'Email',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: '#',
    text: 'Action',
    headerStyle: { width: '150px', textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <div className="container-button">
          <div className="rounded" onClick={() => handleReview(row)}>
            <StarIcon />
          </div>
          <div className="rounded" onClick={() => onClickUpdateIcon(row)}>
            <CreateIcon />
          </div>
          {/* <div
            className="rounded"
            onClick={() => toogleDeleteConfirmModal(row)}>
            <DeleteOutlineIcon />
          </div> */}
          <div className="rounded" onClick={() => handleApprove(row)}>
            {row.active ? <ClearIcon /> : <CheckIcon />}
          </div>
        </div>
      );
    },
  },
];
